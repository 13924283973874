/*SOME GENERALS */

body {
    background-color: rgb(249, 249, 249);
}

p {
    color: #000000;
}

h1 {
    color: #000000;
}

h2 {
    color: #000000;
}

label {
    color: #000000;
}

main {
    background: transparent !important;
}

.page-intro {
    background-color: #FFFFFF;
}

.al-default-btn {
    background-color: #66ccff;
}

.sq-button {
    background-color: #66ccff !important;
}

.form-content {
    border-radius: 0 !important;
    box-shadow: none !important;
}

.modal-content {
    border-radius: 0;
    box-shadow: none;
}

.drop-previous-btn {
    background-color: #66ccff;
}

.calendar-tickets-text span {
    color: #66ccff;
}

.raffle-description-row {
    background-color: #FFFFFF;
    color: #000000;
    border-radius: 0;
}

.raffle-description-row p {
    color: #000000;
}

.raffle-description-row a {
    color: #66ccff;
}

.ticket-style {
    color: #66ccff;
}

.raffle-tickets-text span {
    color: #66ccff;
}

.unavailable-description-row {
    background-color: #ffffffbf;
}

.unavailable-description-row p {
    color: #000000;
}

.unavailable-description-row a {
    color: #66ccff;
}

.unavailable-description-row i {
    color: #66ccff;
}

.tooltipster-content {
    background-color: #FFFFFF !important;
    color: #000000 !important;
}

.tooltipster-box p {
    color: #000000 !important;
}

/*HEADER BANNER*/

.header-notification a,
.general-header-notification a {
    background-color: #66ccff;
}

/*NAVIGATION*/

#wrapper {
    background-image: none;
    background-color: rgb(249, 249, 249);
    margin: 0;
    padding: 80px 0;
}

#push {
    height: 0;
}

header.app-header {
    background: #fff;
    border-bottom: 3px solid;
}

.menu-action-btn .menu-buttons {
    background: #66ccff;
}

.menu-action-btn .usd-button {
    color: #66ccff;
}

#open-menu {
    background-image: url("../assets/menu-outline-blue.png");
}

.sidenav {
    background: #FFFFFF;
}

.sidenav a:hover {
    color: #66ccff;
}

.sidenav ul li .submenu:hover {
    color: #66ccff;
}

.sidenav ul li a,
.sidenav ul li .submenu {
    color: #000000;
}

.sidenav ul li .children a {
    color: #66ccff;
}

.blue-menu {
    color: #66ccff !important;
}

.sidenav #close-btn {
    background-image: url("../assets/close2.png");
    margin-top: -3px;
}

.pos-rel {
    position: relative;
}

.new-nav {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
}

.stretch-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
}

.hero-image {
    margin-top: -20px;
}

.hero-image img {
    width: 100%;
}

.al-icon img {
    width: 50px;
}

.content-infos {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
}

.al-feature-info {
    display: grid;
}

.al-feature-info li {
    list-style: none;
    display: inline-flex;
    padding: 20px;
    color: #000000;
}

.al-feature-title h1 {
    padding: 20px 0;
    color: #66ccff;
    margin: 0;
    padding-top: 50px;
}

.al-icon-text {
    font-weight: 900;
    font-size: 2.4rem;
    padding-left: 20px;
    margin: auto 0;
    width: 200px;
    line-height: 30px;
}

.hero-sec-content {
    background: #343D4B;
}

.al-feature-sec-info {
    color: #fff;
    text-align: center;
}

.al-feature-sec-info li {
    list-style: none;
    padding: 20px 0;
}

.al-feature-sec-info li div {
    margin-bottom: 15px;
}

.al-icons-text {
    font-weight: 900;
    font-size: 2.4rem;
}

.al-feature-title {
    text-align: center;
}

.al-carousel-img img {
    width: 100%;
    background: #fff;
    /*border-top-left-radius: 20px;*/
    /*border-top-right-radius: 20px;*/
    /*border-bottom-left-radius: 20px;*/
    /*box-shadow: 10px 10px 0 0 rgba(0, 0, 0, 0.38);*/
    -o-object-fit: cover;
    height: 180px;
    object-fit: contain;
}

.al-home-item {
    padding: 20px;
    width: 50%;
}

.al-carousel-meta {
    margin-top: 20px;
    color: #fff;
}

.al-carousel-meta li {
    display: inline;
    background: #66ccff;
    padding: 5px 10px;
    border-radius: 20px;
    margin-right: 20px;
}

.al-carousel {
    display: flex;
    max-width: 550px;
    margin: 0 auto;
    padding-top: 40px;
}

i.fa.fa-dropbox {
    font-size: 50px;
    margin-top: 5px;
}

.al-icon i {
    font-size: 7rem;
    color: #66ccff;
}

.al-icon-text p {
    font-size: 15px;
    margin-top: 10px;
    color: #000000;
}

/*FOOTER*/

.al-footer-grid2 {
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: center;
}

.insta-icon {
    font-size: 4rem;
}

.insta-icon i {
    color: #FFFFFF;
}

.al-footer-left p {
    padding: 5px;
}

.al-footer-right {
    color: #fff;
    margin: auto 0;
}

.al-footer-left p a {
    color: #fff;
}

.al-footer-left p a:hover {
    color: #66ccff;
}

#al-footer {
    background-color: rgb(0, 0, 0);
    padding: 30px 0;
    /*margin-top: -130px;*/
}

.al-logo img {
    width: 100%;
    max-width: 200px;
    margin-bottom: 10px;
    margin-left: -2px;
}

.al-footer-left {
    text-align: left;
    padding-left: 30px;
}

.insta-icon {
    font-size: 4rem;
    padding-left: 5px;
}

/*GENERAL STYLE */

/*DROPLIST REDESIGN*/

.droplist-headline {
    color: #000000;
    font-weight: bold;
    font-size: 1.8rem;
    margin: 20px 0;
}

.droplist-header-description {
    background: #fff;
    padding: 2px 10px;
}

.droplist-header-text>p {
    color: #000;
    text-transform: uppercase;
}

/*.droplist-header-text > p > span {*/

/*    color: #66ccff;*/

/*}*/

.droplist-header-text a {
    color: #66ccff;
}

.drop-head-tit {
    font-size: 2rem;
    font-weight: bold;
}

.release-tags {
    margin-top: 10px;
}

.release-tags>li.active {
    background: #66ccff;
    border-radius: 0;
}

.release-tags>li a {
    background: #CCCCCC;
    border-radius: 0;
}

.release-tags>li a:hover {
    background: #86d0fc;
}

ul.release-tags {
    padding-inline-start: 0;
}

.droplist-container>.number-pic>img {
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    /*box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;*/
    box-shadow: none;
    padding: 20px;
}

.droplist-container>.number-pic>video {
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    /*box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;*/
    box-shadow: none;
    padding: 20px;
}

.droplist-container>.number-pic>.num-pic {
    background: #66ccff;
}

.droplist-container>.number-pic>.num-pic>p {
    color: #000000;
}

.droplist-container>.number-pic>.tile-price {
    color: #66ccff;
    align-items: baseline;
    /*text-transform: uppercase;*/
}

.droplist-container>.number-pic>.tile-price>.cross-text {
    color: #000000;
}

.droplist-container>.number-pic>.tile-price>.divBbl>.prod-bubble {
    color: #000000;
    background: none;
    font-weight: bold;
}

.droplist-container>.number-pic>.drop-banner {
    color: #000000;
    font-weight: bold;
}

.droplist-container>.number-pic>.drop-banner {
    background-image: url("https://trusted-trader-bucket.s3.amazonaws.com/public/tile-banner-blue.png");
}

.droplist-container>.number-pic>.drop-banner.annual {
    background-image: url("https://trusted-trader-bucket.s3.amazonaws.com/public/gold-banner.png");
    color: #000000;
}

.droplist-container>.number-pic>.drop-banner.drop-sold-out {
    background-image: url("https://trusted-trader-bucket.s3.amazonaws.com/public/tile-banner-red.png");
    color: #FFFFFF;
}

a.play-nxt {
    background: #66ccff;
}

.aweber-mystery-btn input {
    background-color: #66ccff;
    color: #000000;
}

a.play-nxt:hover {
    background: #0D1422;
    color: #FFFFFF;
}

a.twobtns-green-before {
    background: #66ccff;
}

a.twobtns-green {
    background: #66ccff;
}

/*DROP DETAIL PAGE*/

.listing-view p {
    color: #000000;
}

.listing-view>.listing-left>.images>.current-image {
    box-shadow: none;
    border-radius: 0;
}

.listing-view>.listing-right>h1 {
    color: #000000;
}

.listing-view>.listing-right>.description-row {
    color: #000000;
}

.listing-view>.listing-right>.description-row.condition>h3 {
    color: #000000;
}

.listing-view>.listing-right>.description-row.condition {
    background-color: #FFFFFF;
}

.listing-view>.listing-right>ul.buttons-custom>li>a {
    background-color: #66ccff;
}

.listing-drop .description-row.condition {
    background-color: #FFFFFF !important;
    border-radius: 0 !important;
}

.droplist-lists>.featuredSection>h2 {
    color: #000000;
}

.droplist-lists .image {
    background-color: #FFFFFF !important;
    box-shadow: none !important;
    border-radius: 0 !important;
}

.droplist-lists .info>h4 {
    color: #000000 !important;
}

/*GAMES*/

.leaderboard-description a,
.show-more-description {
    color: #66ccff;
}

.leaderboard h3 {
    color: #000000;
}

.leaderboard-container li {
    background-color: #66ccff;
}

.game1 .game-image img,
.timerbox {
    border-radius: 0;
    box-shadow: none;
}

.theoptions .option {
    background: #66ccff;
    color: #FFFFFF;
}

button.btn-nxt-game {
    background: #66ccff;
    color: #FFFFFF;
}

button.btn-nxt-game:hover {
    background: #66ccff;
    color: #000000;
}

button.btn-nxt-game:active {
    background: #66ccff;
}

.dashboard h4 {
    color: #66ccff;
}

.fab.teal,
a.fab.teal.share-button p {
    background: #66ccff;
}

/*PRODUCT BUY*/

.listing-info .checkout-nav li {
    background-color: #66ccff;
}

.listing-info .checkout-nav li h2 {
    color: #000000;
}

.listing-info .info {
    border: 2px solid #66ccff;
}

a#coder {
    color: #66ccff;
}

i.fa.fa-caret-right {
    color: #66ccff;
}

/*RELEASE CALENDAR */

.release-container>a>.number-pic>img {
    border-radius: 0;
    box-shadow: none;
}

.release-container>a>.number-pic>.num-pic {
    background-color: #66ccff;
}

.pred-img img {
    border-radius: 0;
    box-shadow: none;
}

.pred-img a figure {
    border-radius: 0;
    box-shadow: none;
}

.pred-btn1 {
    background: #70ccff;
}

.pred-btn2 {
    background: #60b1df;
}

.win-here {
    color: #66ccff;
}

.slider {
    border: 2px solid #66ccff;
}

.slider::-webkit-slider-thumb {
    border: 2px solid #66ccff;
}

span#userT {
    color: #66ccff;
}

.btn-exit-game {
    background-color: #66ccff;
}

p.pred-desc {
    color: #000000;
}

/*RAFFLE*/

.listing-list ul li .image {
    box-shadow: none;
    border-radius: 0;
    background-color: #FFFFFF;
}

.popular-container li .image {
    border-radius: 0;
}

.raffle-format {
    color: #000000;
}

.closed {
    filter: none;
}

.drawing-block .drawing {
    background-color: #FFFFFF;
    border-radius: 0;
}

.drawing-block .drawing h4 {
    color: #000000;
}

.drawing-block .drawing h5 {
    color: #000000;
}

.drawing-btn h3 {
    color: #FFFFFF;
}

.drawing-block .drawing-btn {
    background-color: #66ccff;
    border-radius: 0;
}

.share-buttons h4 {
    color: #66ccff;
}

.share-buttons i {
    color: #66ccff;
}

.drawing-pemium-btn {
    background-color: #66ccff;
    border-radius: 0;
}

.drawing-pemium-btn h3 {
    color: #FFFFFF;
}

.modal#tickets .ticket-value {
    color: #66ccff;
}

.modal#tickets #raffleId {
    background-color: #66ccff;
    color: #FFFFFF;
}

/*SALE*/

.sale-table tr td.checkmark {
    color: #66ccff;
}

.sale-table tr td {
    background-color: #FFFFFF;
}

.sale-table tr td .green-btn {
    background-color: #66ccff;
    color: #FFFFFF;
}

/*LOGINS*/

.register-btn input {
    background-color: #66ccff;
}

.login-form h1,
.register-form h1 {
    color: #66ccff;
}

input[type=submit].btn-primary {
    background-color: #66ccff;
}

/*PURCHASE*/

.featitm {
    border-radius: 0;
    box-shadow: none;
}

/*MARKETPLACE*/

.product-info h5:hover {
    background-color: #66ccff;
}

.featuredSection h2 {
    color: #66ccff;
}

.filters-toggle a i {
    color: #66ccff;
}

.drop-btn:hover {
    color: #66ccff !important;
}

/*ACCOUNT*/

.account-settings {
    background-color: #FFFFFF;
}

.account-settings .sidebar-menu-header {
    color: #66ccff;
}

.account-settings .nav-pills>div>a {
    color: #66ccff;
}

.account-settings .sidebar-menu-header .burger-menu span {
    background-color: #66ccff;
}

/*MY LISTINGS*/

.inventory-row {
    background-color: #FFFFFF;
    color: #000000;
}

.inventory-row:hover {
    background-color: rgb(102 204 255 / 50%);
}

.listing-btn {
    color: #66ccff;
}

/*LIVE*/

.featuredSection ul li .info h4 {
    color: #000000;
}

.tile-price span.prod-bubble {
    background-color: #66ccff;
}

.live-page .drawing-block .drawing {
    background-color: #FFFFFF;
}

.live-page .drawing-block h3 {
    padding: 20px 100px;
}

.live-page .live-page-btn .live-share-buttons {
    background-color: #FFFFFF;
    color: #000000;
    border-radius: 0;
}

.live-page .live-page-btn .live-share-buttons i {
    color: #66ccff;
}

.live-page .live-page-content {
    color: #000000;
}

.live-page .raffle-description-row {
    background-color: #FFFFFF;
}

/*FLASH SALE*/

.live-page .flash-page-products ul li .image {
    border-radius: 0;
    box-shadow: none;
    background-color: #FFFFFF;
}

.live-page .flash-page-products ul li .tile-banner {
    background-image: url("https://trusted-trader-bucket.s3.amazonaws.com/public/tile-banner-blue.png");
}

.live-page .flash-page-products ul li .product-price span {
    color: #000000;
}

li.twobtns-green {
    background: #66ccff;
}

/*SURVEYS*/

.surveyQ button {
    background-color: #66ccff;
    color: #FFFFFF;
}

.buttons-buy {
    background-color: #66ccff;
    color: #000000;
}

.listing-view .listing-right .description-row.protection span {
    color: #66ccff;
}

.listing-view .listing-right .description-row.condition {
    border-radius: 0;
    background-color: #FFFFFF;
}

.listing-view .listing-right .description-row.condition p {
    color: #000000 !important;
}

/*responsive*/

.videoContainar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: initial;
}

.playButton {
    position: absolute;
    border: 0;
    background: transparent;
    /*box-sizing: border-box;*/
    /*width: 0;*/
    /*!*height: 74px;*!*/
    /*margin-left: 10px;*/
    /*border-color: transparent transparent transparent #202020;*/
    /*transition: 100ms all ease;*/
    /*cursor: pointer;*/
    /*border-style: solid;*/
    /*border-width: 25px 0 25px 45px;*/
    /*display: none;*/
}

.playButton i {
    display: none;
}

/*.paused {*/

/*    border-style: double;*/

/*    border-width: 0px 0 0px 60px;*/

/*}*/

/*.videoContainar.playButton:hover {*/

/*    border-color: transparent transparent transparent #404040;*/

/*}*/

.buyEth {
    margin-bottom: 5px;
}

@media (max-width: 500px) {
    .al-carousel-img img {
        height: 150px;
    }
    .al-carousel-meta li {
        padding: 5px 10px;
        margin-right: 5px;
        font-size: 12px;
    }
    .al-footer-left {
        text-align: left;
        padding-left: 0px;
    }
    .playButton i {
        display: none;
    }
    .playButton.hidePlay i {
        display: block;
    }
}

.transfer-wrapper{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.transfer-elements {
    display: flex;
    justify-content: space-between;
    margin: 20px;
}
