/***** General *****/

/* The html and body elements cannot have any padding or margin. */

html,
body {
    height: 100%;
}

body {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 1.3em;
    font-weight: 300;
    background-color: #3f4c61;
}

.button-opentype {
    background-color: #66ccff;
    border: none;
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
}

.button1 {border-radius: 2px;}

h1 {
    font-size: 38px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: #FFFFFF;
}

h2 {
    font-size: 48px;
    font-family: 'Roboto', sans-serif;
    font-weight: 200;
    color: #FFFFFF;
}

h3 {
    font-size: 32px;
    font-family: 'Roboto', sans-serif;
    color: #404B5E;
}

h4 {
    font-size: 24px;
    font-family: 'Roboto', sans-serif;
    font-weight: 200;
    color: #2C2C2C;
}

a {
    color: #404B5E;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

a:hover {
    color: #C60B31;
}

p {
    line-height: normal;
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

.content-wrapper {
    padding-top: 0px;
}

label {
    color: #B1B1B1;
}

input.form-control {
    padding: 0 15px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #E7E7E7;
    font-size: 16px;
    line-height: 40px;
    height: 40px;
    -webkit-text-fill-color: #333333;
    opacity: 1;
    /* required on iOS */
}

.btn-primary {
    height: 50px;
    border-radius: 2px;
    background: #C60B31;
    border-color: #C60B31;
    color: #FFFFFF;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
    background: #333333;
}

/* Wrapper for page content to push down footer */

#wrapper {
    padding-top: 80px;
    min-height: 90vh;
    height: 90vh !important;
    background-color: #3f4c61;
    background-image: url("https://sneaker.imgix.net/https%3A%2F%2Ftrusted-trader-bucket.s3.amazonaws.com%2Fpublic%2Fbackground.png?auto=compress&s=ed96d9534e3efbe76be5bb725e5d4ffb");
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    /* Negative indent footer by it's height */
    margin: 0 auto -115px;
}

/* Set the fixed height of the footer here */

#push,
#footer {
    height: 10vh;
}

/** Forms **/

/*.forms {*/

/*    padding: 80px 15px;*/

/*}*/

.forms h1 {
    margin-bottom: 40px;
    margin-top: 0px;
    font-size: 30px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 4px;
}

.locked {
    pointer-events: none;
    opacity: 0.5;
}

.forms .no-account {
    margin: 15px 0 15px 0;
    color: #929292;
}

.form-content {
    padding: 40px;
    background: #FFFFFF;
}

.form-content a {
    display: block;
    width: auto;
    margin-top: 15px;
    color: #FFFFFF;
}

.form-content .btn {
    margin-top: 10px;
}

#userMessage_textarea {
    resize: vertical;
}

@media ( max-width: 1199px) {
    .form-content {
        padding: 20px;
    }
    .form-content h1 {
        margin-bottom: 20px;
    }
}

.info-content {
    padding: 40px;
    background: #FFFFFF;
}

.info-content a {
    color: #999CA5;
}

@media ( max-width: 1199px) {
    .info-content {
        padding: 20px;
    }
}

/***** Header *****/

@media ( min-width: 1200px) {
    .app-header .container {
        padding: 15px 50px;
        width: 100%;
    }
}

@media ( max-width: 1199px) {
    .app-header .container {
        padding: 15px 50px;
        width: 100%;
    }
}

@media (max-width: 991px) {
    .app-header .container {
        padding: 15px;
    }
}

@media (max-width: 767px) {
    .navbar-header {
        display: inline-block;
        margin-left: 0 !important;
    }
    .menu-action-btn {
        display: inline-block;
        float: right;
    }
    .app-header .container {
        padding: 15px 5%;
    }
    .form-content {
        padding: 20px 0;
    }
    .timerbox {
        width: 200px !important;
        padding: 15px !important;
    }
    .theoptions .option {
        padding: 6px 11px !important;
        font-size: 1.3rem !important;
    }
    timecontent {
        font-size: 20px !important;
    }
}

.app-header .container {
    position: relative;
}

.alert>ul {
    list-style-type: square;
    padding-left: 20px;
}

.navbar {
    border-radius: 0px;
    border: 0px;
    background-color: transparent;
    width: 100%;
    min-height: 80px;
    max-width: 1470px;
    margin: 0 auto;
}

.navbar-header {
    position: relative;
    z-index: 15;
}

.navbar-brand {
    height: auto;
    margin: 0 !important;
}

.navbar-brand img {
    display: block;
    width: auto;
    max-height: 50px;
}

@media (max-width: 767px) {
    .navbar-brand img {
        width: 183px;
        height: auto;
        padding: 0;
    }
}

.navbar-inverse .navbar-nav>li>a {
    color: #12C196;
    font-family: 'SofiaProSoftW01-Regular', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 2px;
}

@font-face {
    font-family: "SofiaProSoftW01-Regular";
    src: url("//db.onlinewebfonts.com/t/47ff3156fe928e750d0468143555356f.eot");
    src: url("//db.onlinewebfonts.com/t/47ff3156fe928e750d0468143555356f.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/47ff3156fe928e750d0468143555356f.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/47ff3156fe928e750d0468143555356f.woff") format("woff"), url("//db.onlinewebfonts.com/t/47ff3156fe928e750d0468143555356f.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/47ff3156fe928e750d0468143555356f.svg#SofiaProSoftW01-Regular") format("svg");
}

/*.navbar-inverse .navbar-nav>li>a.user-menu-icon {*/
/*    padding: 0px;*/
/*    background: url("../img/user.svg") no-repeat center center;*/
/*    background-size: 40px 40px;*/
/*    width: 40px;*/
/*    height: 40px;*/
/*    font-size: 0px;*/
/*    color: transparent;*/
/*    text-decoration: none;*/
/*}*/

/*.navbar-inverse .navbar-nav>li>a.user-menu-icon:hover {*/
/*    text-decoration: none;*/
/*    background: url("../img/user-hover.svg") no-repeat center center;*/
/*    background-size: 40px 40px;*/
/*    width: 40px;*/
/*    height: 40px;*/
/*    font-size: 0px;*/
/*    color: transparent;*/
/*    text-decoration: none;*/
/*}*/

.navbar-inverse .navbar-nav>li>a.user-menu-icon .caret {
    display: none;
    color: #12C196;
}

.navbar-inverse .navbar-nav>li>a:hover,
.navbar-inverse .navbar-nav>li>a:focus {
    color: #FFFFFF;
}

.navbar-inverse .navbar-nav>.active>a,
.navbar-inverse .navbar-nav>.active>a:focus,
.navbar-inverse .navbar-nav>.active>a:hover {
    color: #FFFFFF;
    background-color: transparent;
    text-decoration: underline;
    text-underline-position: under;
}

.navbar-inverse .navbar-nav>.open>a,
.navbar-inverse .navbar-nav>.open>a:focus,
.navbar-inverse .navbar-nav>.open>a:hover {
    color: #000000;
    background-color: transparent;
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
    border-color: #12C196;
}

.site-main-color {
    color: #12C196;
}

.site-secondary-color {
    color: #FFFFFF;
}

.navbar-inverse .navbar-nav .open .dropdown-menu .divider {
    background-color: #F6F6F6;
}

.navbar-nav .open .dropdown-menu .dropdown-header,
.navbar-nav .open .dropdown-menu>li>a {
    padding-left: 15px;
    color: #12C196;
    border-radius: 20px 20px 20px 20px;
}

.navbar-inverse .navbar-nav .open .dropdown-menu>li>a:focus,
.navbar-inverse .navbar-nav .open .dropdown-menu>li>a:hover {
    color: #111111;
}

.navbar-inverse .navbar-nav .open .dropdown-menu>.active>a,
.navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:focus,
.navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:hover {
    color: #000000;
    background: transparent;
}

.navbar .navbar-collapse {
    position: relative;
    text-align: center;
}

.navbar .navbar-center {
    position: relative;
    display: block;
    float: none;
    margin-left: -50px;
    width: 100%;
    padding-top: 5px;
    text-align: center;
    z-index: 10;
}

.navbar .navbar-center>li {
    display: inline-block;
    float: none;
    padding-left: 10px;
    padding-right: 10px;
}

.navbar .navbar-center>li a i {
    display: none;
}

.navbar .navbar-center>li a .caret {
    color: #CCCCCC;
    border-top: 6px dashed;
    border-top: 6px solid \9;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
}

.navbar .navbar-right {
    position: absolute;
    top: 10px;
    right: 15px;
}

.navbar .navbar-right .user-login a {
    padding: 0px;
    color: #12C196;
}

.navbar-inverse .navbar-toggle {
    margin-top: 20px;
    padding: 0px;
    background: transparent;
    border-color: transparent;
}

.navbar-inverse .navbar-toggle:focus,
.navbar-inverse .navbar-toggle:hover {
    background: transparent;
}

.navbar-inverse .navbar-toggle .icon-bar {
    background-color: #FFFFFF;
    height: 4px;
    width: 30px;
}

.navbar-inverse .navbar-toggle:focus .icon-bar,
.navbar-inverse .navbar-toggle:hover .icon-bar {
    background-color: #12C196;
}

@media ( max-width: 991px) {
    .navbar .navbar-center {
        float: left;
        margin-left: 0px;
        width: auto;
        padding-top: 9px;
        text-align: center;
    }
    .navbar .navbar-center>li {
        padding-left: 0px;
        padding-right: 0px;
    }
    .navbar .navbar-center>li a {
        padding-left: 10px;
        padding-right: 10px;
        color: #12C196;
    }
}

@media ( max-width: 767px) {
    .navbar .navbar-center {
        width: 100%;
        text-align: left;
        color: #12C196;
    }
    .navbar .navbar-center>li {
        display: block;
        float: left;
        width: 100%;
    }
    .navbar .navbar-center>li>a {
        padding-left: 0px;
        padding-right: 0px;
        color: #12C196;
    }
    .navbar .navbar-right {
        position: static;
        float: left;
        width: 100%;
        text-align: left;
        margin-left: 0px;
    }
    .navbar-inverse .navbar-nav>li>a.user-menu-icon {
        padding-top: 10px;
        padding-bottom: 10px;
        background: transparent;
        font-size: 16px;
        color: #12C196;
        width: auto;
        height: auto;
    }
    .navbar-inverse .navbar-nav>li>a.user-menu-icon .caret {
        display: inline-block;
        color: #CCCCCC;
        border-top: 6px dashed;
        border-top: 6px solid \9;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
    }
    .navbar {
        min-height: auto;
    }
}

/***** Footer *****/

.app-footer {
    padding: 30px 50px;
    background: #3f4c61;
}

.app-footer a {
    color: #fff;
}

.app-footer p {
    margin-bottom: 0px;
    font-size: 16px;
}

.app-footer .enjoy {
    margin-bottom: 10px;
    font-size: 26px;
    color: #414C5E;
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
    background-image: url("https://sneaker.imgix.net/https%3A%2F%2Ftrusted-trader-bucket.s3.amazonaws.com%2Fpublic%2Fbackground.png?auto=compress&s=ed96d9534e3efbe76be5bb725e5d4ffb");
}

@media ( max-width: 768px) {
    .app-footer {
        padding: 30px 30px;
    }
}

/** General elements for pages **/

.page-intro {
    position: relative;
    padding: 120px 0px;
    background: rgba(0, 0, 0, .5);
    text-align: center;
}

.page-intro p {
    margin: 0px auto;
    padding-bottom: 20px;
    font-size: 18px;
    color: #ABABAB;
    max-width: 880px;
}

.page-intro p a {
    color: #ABABAB;
}

.page-intro p.welcome {
    letter-spacing: 5px;
    text-transform: uppercase;
    color: #464646;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.3em;
}

.page-intro h1 {
    margin-top: 30px;
    margin-bottom: 40px;
    font-size: 100px;
    letter-spacing: 5px;
}

@media ( max-width: 1199px) {
    .page-intro h1 {
        font-size: 86px;
    }
}

@media ( max-width: 991px) {
    .page-intro {
        padding: 100px 0px;
    }
    .page-intro h1 {
        font-size: 70px;
    }
}

@media ( max-width: 768px) {
    .page-intro {
        padding: 80px 0px 50px 0px;
    }
    .page-intro h1 {
        font-size: 50px;
    }
}

@media ( max-width: 500px) {
    .page-intro {
        padding: 80px 0px 0px 0px;
    }
    .page-intro h1 {
        font-size: 200%;
    }
}

/** Home page **/

.home-intro p.welcome {
    font-size: 26px;
}

.home-intro h1 {
    font-size: 80px;
}

.home-intro .arrow_down {
    position: absolute;
    bottom: -102px;
    left: 0px;
    width: 100%;
    overflow: hidden;
    text-align: center;
}

/*.home-intro .arrow_down .arrow_down_content {*/
/*    margin: 0px auto;*/
/*    background: url("../img/arrow_center.png") no-repeat center center;*/
/*    width: 654px;*/
/*    height: 103px;*/
/*}*/

@media ( max-width: 991px) {
    .home-intro h1 {
        font-size: 60px;
    }
}

@media ( max-width: 768px) {
    .home-intro h1 {
        font-size: 34px;
    }
    .home-intro .arrow_down .arrow_down_content {
        width: 100%;
    }
}

.home-list {
    padding-top: 150px;
    padding-bottom: 70px;
    background: #FFFFFF;
    text-align: center;
}

.home-list .col-md-4 {
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
}

.home-list h2 {
    margin-top: 50px;
}

.home-list h2,
.home-list h2 a {
    font-size: 42px;
    color: #2C2C2C;
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
}

.home-list h3 a {
    font-size: 30px;
    color: #2C2C2C;
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
}

.home-list .panel-group {
    margin-top: 30px;
    text-align: left;
}

.home-list .panel-default {
    border-color: transparent;
    box-shadow: none;
}

.home-list .panel-group .panel+.panel {
    margin-top: 0px;
}

.home-list .panel-group .panel {
    border-radius: 0px;
    border-bottom: 1px solid #B8B8B8;
}

.home-list .panel-heading {
    padding: 15px;
    border-radius: 0px;
    background-color: transparent;
}

.home-list .panel-heading h4 a {
    text-decoration: none;
    color: #555ec4;
    font-size: 26px;
}

.home-list .panel-heading h4 a.open {
    padding-left: 30px;
    background: url("../assets/menu-outline-blue.png") no-repeat left center;
}

/*.home-list .panel-heading h4 a.closed {*/
/*    padding-left: 30px;*/
/*    background: url("../img/close.png") no-repeat left center;*/
/*}*/

.home-list .panel-heading h4 a:hover {
    color: #000000;
}

.home-list .panel-default>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: transparent;
}

.home-list .panel-body ul {
    padding-left: 20px;
    margin: 0px;
}

.home-list .panel-body ul li {
    line-height: 1.3em;
}

@media ( max-width: 1199px) {
    .home-list h2,
    .home-list h2 a {
        font-size: 36px;
    }
}

@media ( max-width: 767px) {
    .home-list h3 a {
        font-size: 26px;
    }
    .home-list .panel-heading h4 a {
        font-size: 20px;
        line-height: 1.3em;
    }
}

/** About page **/

.about-list {
    padding-top: 70px;
    padding-bottom: 70px;
    background: #FFFFFF;
    text-align: center;
}

.about-list .col-md-4 {
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
}

.about-list h2 a {
    font-size: 42px;
    color: #2C2C2C;
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
}

@media ( max-width: 1199px) {
    .about-list h2 a {
        font-size: 36px;
    }
}

/** Account settings **/

.account-intro {
    position: relative;
    padding: 0;
    text-align: center;
}

.account-titles {
    position: relative;
    padding: 5px 0px;
    background: #F6F6F6;
    text-align: center;
}

.account-intro h1 {
    margin-top: 0px;
    font-size: 30px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 4px;
}

.account-titles h2 {
    margin-top: 0px;
    font-size: 25px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 4px;
}

.account-settings {
    padding: 0px 15px 0px 15px;
    background: rgba(0, 0, 0, 0.46);
}

.account-settings .row {
    padding: 25px 25px 40px 25px;
}

.account-settings .btn-primary {
    width: auto !important;
}

.account-settings .sidebar {
    display: block;
}

.account-settings .sidebar-menu-header {
    position: relative;
    margin-top: 15px;
    padding: 10px 15px 10px 0px;
    color: rgb(9, 194, 151);
    ;
    border-bottom: 1px solid #E7E7E7;
    cursor: pointer;
    display: none;
    font-weight: 400;
    text-transform: uppercase;
}

.account-settings .sidebar-menu-header .burger-menu {
    position: absolute;
    top: 9px;
    right: 0px;
    width: 20px;
}

.account-settings .sidebar-menu-header .burger-menu span {
    display: block;
    margin: 3px 0px;
    height: 3px;
    background: rgb(9, 194, 151);
    ;
}

.account-settings .sidebar {
    margin-top: 15px;
}

.account-settings .nav-pills>div>a {
    border-radius: 0px;
    color: #09C297;
}

.account-settings .nav-pills>li>a {
    border-radius: 0px;
}

.account-settings .nav-pills>li>a {
    padding-left: 0px;
}

.account-settings .nav-pills>li.active>a,
.account-settings .nav-pills>li.active>a:focus,
.account-settings .nav-pills>li.active>a:hover {
    background: transparent;
    color: #000000;
}

.account-settings .nav-pills>li>a:focus,
.account-settings .nav-pills>li>a:hover {
    background: transparent;
    color: #C60B31;
}

@media ( max-width: 767px) {
    .account-settings {
        padding: 0px;
    }
    .account-settings .row {
        padding: 15px 0px 30px 0px;
    }
    .sidebar-container {
        margin-bottom: 30px;
    }
    .sidebar-container .sidebar-menu-header {
        display: block;
    }
    .sidebar-container .sidebar {
        display: none;
    }
}

.account-settings p.not-implemented {
    margin-top: 15px;
    font-size: 20px;
}

.account-settings .spacer {
    height: 15px;
}

.account-settings p.intro {
    margin-top: 15px;
    font-size: 24px;
}

.no-image {
    width: 100%;
    height: 100%;
    background: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.no-image::after {
    font-family: FontAwesome;
    content: "\f03e";
    padding-top: 10px;
    font-size: 60px;
    color: #aaa;
    -ms-transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
}

.listing-home {
    width: 100%;
    max-width: 1570px;
    margin: 0 auto;
    padding: 0 15px;
}

.listing-list ul li {
    width: 275px;
    min-height: 220px;
    margin: 5px 25px 20px 25px;
}

@media (max-width: 767px) {
    .listing-home {
        padding: 0 5%;
    }
}

.calendar-raffle {
    display: block;
    width: 100%;
    max-width: 1110px;
    margin: 0 auto;
}

.listing-list ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
}

.listing-list ul::after {
    flex: auto;
}

/*.listing-list ul li {*/

/*    width: 275px;*/

/*    min-height: 220px;*/

/*    margin: 5px 25px 20px 25px;*/

/*}*/

.mk-filter {
    background-color: #ffffff;
    padding: 0 20px;
    border-radius: 6px;
}

.listing-list ul li:nth-child(3n+3) {
    /*margin-right: 0;*/
}

.readysetgo p {
    font-size: 45px;
    color: red;
    font-style: italic;
    text-shadow: -1px 0 0px black, 0 1px black, 1px 0 black, 0 -1px black;
}

.listing-list ul li .image {
    width: 100%;
    height: 220px;
    overflow: hidden;
    background-color: #f9f9f9;
    -webkit-border-radius: 20px;
    -webkit-border-bottom-right-radius: 0;
    -moz-border-radius: 20px;
    -moz-border-radius-bottomright: 0;
    border-radius: 20px;
    border-bottom-right-radius: 0;
    box-shadow: 10px 10px 0 0 rgba(0, 0, 0, 0.38);
}

.listing-list ul li .image a {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 20px 20px 0px 20px;
    -moz-border-radius: 20px 20px 0px 20px;
    -webkit-border-radius: 20px 20px 0px 20px;
    border: 10px solid #FFFFFF;
}

.listing-list ul li .image img {
    display: block;
    max-width: 100%;
    max-height: 220px;
}

.product-info {
    display: flex;
    align-items: center;
    padding: 20px 0 10px 0;
}

.product-info h5 {
    padding: 4px 9px;
    margin: 0 6px 0 0;
    color: #ffffff;
    font-size: 20px;
    background-color: #313a48;
    border-radius: 20px;
    font-weight: 400;
}

.product-info h5:hover {
    background-color: #12c196;
}

.listing-list ul li .info {
    width: 100%;
    position: relative;
}

.listing-list ul li h3 {
    margin: 10px 0;
    padding: 0 100px 0 0;
    font-size: 22px;
    font-weight: 400;
    line-height: 140%;
}

.listing-list ul li a {
    text-decoration: none;
}

.listing-list ul li .info .product-likes {
    position: absolute;
    right: 0;
    top: 0;
}

.listing-list ul li .info .product-likes i {
    font-size: 20px;
    padding: 0 2px 0 0;
    color: #262B34;
}

.listing-list ul li .info .product-likes .vote-info {
    padding: 0 0 0 6px;
}

.listing-list ul li .info .product-likes i:hover,
.listing-list ul li .info .product-likes i.active {
    color: #12C196;
}

.listing-view {
    display: flex;
    width: 100%;
    padding: 0;
}

.listing-view::after {
    content: "";
    display: table;
    clear: both;
}

.listing-view .listing-right {
    margin-top: 20px;
    width: 100%;
}

.listing-view .listing-right h1 {
    margin: 0 0 25px 0;
}

@media(max-width: 767px) {
    .listing-list ul {
        justify-content: center;
    }
}

.listing-view .listing-right .description-row {
    color: #ffffff;
    font-size: 20px !important;
    padding: 5px 0;
}

.listing-view .listing-right .description-row.sku {
    display: none;
}

.listing-view .listing-right .description-row.protection {
    margin: 0 0 30px 0;
}

.listing-view .listing-right .description-row.protection span {
    color: #09C297;
    font-weight: bold;
}

.listing-view .listing-right .description-row.condition {
    display: inline-block;
    padding: 15px 25px;
    background-color: #1a1f2a;
    border-radius: 10px 10px 0px 10px;
    -moz-border-radius: 10px 10px 0px 10px;
    -webkit-border-radius: 10px 10px 0px 10px;
}

.raffle-description-row {
    color: #ffffff;
    font-size: 22px;
    padding: 5px 0;
}

.raffle-description-row .protection span {
    color: #09C297;
    font-weight: bold;
}

.raffle-description-row {
    display: inline-block;
    padding: 15px 24px;
    background-color: #1a1f2a;
    border-radius: 10px 0px 10px 10px;
    -moz-border-radius: 10px 0px 10px 10px;
    -webkit-border-radius: 10px 0px 10px 10px;
}

.unavailable-description-row {
    display: inline-block;
    padding: 15px 24px;
    opacity: 0.8;
    background-color: #2c3644;
    border-radius: 10px 0px 10px 10px;
    -moz-border-radius: 10px 0px 10px 10px;
    -webkit-border-radius: 10px 0px 10px 10px;
}

.unavailable-description-row i {
    opacity: 0.8;
    color: #ffffff;
}

.unavailable-description-row p {
    opacity: 0.8;
}

.listing-view .listing-right .description-row.condition h3 {
    margin: 0 0 6px 0;
    font-size: 22px;
    color: #ffffff;
    font-weight: 400;
}

.listing-view .listing-right .description-row.condition p {
    font-size: 18px;
    margin: 0;
    padding: 0;
}

.listing-view .listing-right .product-likes a {
    color: #ffffff;
}

.listing-view .listing-right .value {
    color: #ffffff;
}

.listing-view .listing-right .description {
    margin: 30px 0;
}

.listing-view .listing-right .description code {
    color: #FFFFFF;
    padding: 10px;
    background-color: transparent;
}

.listing-view .listing-right .category {
    margin: 10px 0 30px 0;
    color: #FFFFFF;
    font-size: 22px;
}

.listing-view .listing-right .product_id {
    margin: 10px 0 10px 0;
    color: #FFFFFF;
    font-size: 18px;
}

.listing-view .listing-right .product_id a {
    font-size: 22px;
    color: #FFFFFF;
}

.listing-view .listing-right .product_id i {
    padding: 0 0 0 5px;
}

.listing-view .listing-right .condition {
    margin: 4px 0;
    color: #FFFFFF;
}

.listing-view .listing-left .value {
    margin: 4px 0;
    color: #FFFFFF;
}

.listing-view .listing-left {
    min-width: 450px;
    margin: 20px 80px 20px 20px;
}

.listing-view .listing-left .current-image {
    width: 100%;
    max-width: 500px;
    background-color: #FFFFFF;
    border-radius: 20px 20px 0px 20px;
    -moz-border-radius: 20px 20px 0px 20px;
    -webkit-border-radius: 20px 20px 0px 20px;
    padding: 20px;
    box-shadow: 20px 20px 0px 5px rgba(38, 43, 52, 0.7);
    -moz-box-shadow: 20px 20px 0px 5px rgba(38, 43, 52, 0.7);
    -webkit-box-shadow: 20px 20px 0px 5px rgba(38, 43, 52, 0.7);
}

@media(max-width: 767px) {
    .calendar-raffle {
        flex-wrap: wrap;
    }
    .listing-view .listing-left {
        margin: 0 0 25px 0;
    }
    .listing-view .listing-right h1 {
        font-size: 22px;
        margin: 0 0 10px 0;
    }
    .listing-view .listing-right .description-row {
        font-size: 26px;
        padding: 5px;
    }
    .listing-view .listing-right .description-row.condition {
        margin: 0 0 20px 0;
    }
    .listing-view .listing-right .description-row.protection {
        margin: 0 0 20px 0;
    }
}

.costum-row:after {
    content: "";
    display: table;
    clear: both;
}

.costum-row {
    display: flex;
    color: #ffffff;
}

.costum-row>.column {
    flex: 50%;
}

.listing-view .listing-left .current-image img {
    display: block;
    max-width: 100%;
    max-height: 100%;
}

.listing-view .listing-left .current-image video {
    display: block;
    max-width: 100%;
}

.listing-view .listing-left ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 20px 0 0 0;
    padding: 0;
}

.listing-view .listing-left ul li {
    display: flex;
    width: 100%;
    height: 90px;
    max-width: calc(25% - 10px);
    margin: 12px 10px 0 0;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    cursor: pointer;
    border: 1px solid #ddd;
}

.current-image>ul>li {
    position: relative;
}

.current-image>ul>li>button {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 25px;
    height: 25px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.current-image>a {
    /*position: absolute;
    top: 5px;
    right: 5px;
    height: 25px;
    max-width: fit-content;
    /*padding: 0px 5px 0px 5px;*!*/
    /*max-height: 400px;*/
    display: block;
    margin: 10px 0 0 0;
}

.listing-view .listing-left ul li:nth-child(4n+4) {
    margin: 12px 0 0 0;
}

.listing-view .listing-left ul li img {
    display: block;
    max-width: 100%;
    max-height: 90px;
}

.images-list {
    list-style: none;
    margin: 10px 0 0 0;
    padding: 0;
}

.images-list li {
    position: relative;
    width: 100%;
    margin: 0 0 10px 0;
    background-color: #f9f9f9;
    overflow: auto;
}

.images-list li .image-name {
    width: 100%;
    padding: 0 70px 0 0;
    min-height: 60px;
    color: #d7d7d7;
    box-sizing: border-box;
}

.images-list li .remove-item {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8494e;
    color: #fff;
    cursor: pointer;
}

.current-listing-images li .rotate-item {
    position: absolute;
    top: 0;
    right: 40px;
    bottom: 0;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #cbcbcb;
    color: #fff;
    cursor: pointer;
}

.images-list li .rotate-item {
    position: absolute;
    top: 0;
    right: 40px;
    bottom: 0;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #cbcbcb;
    color: #fff;
    cursor: pointer;
}

.images-list li img {
    float: left;
    height: 60px;
    width: auto;
    margin: 0 10px 0 0;
}

.file-chooser {
    position: relative;
    overflow: hidden;
}

.file-chooser input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.file-chooser-btn {
    width: 100%;
    border: 1px solid #204d74;
    color: #fff;
    background-color: #286090;
    padding: 6px 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}

.file-chooser-btn i {
    font-size: 14px;
}

.current-listing-images {}

.current-listing-images ul {
    list-style: none;
    margin: 10px 0 20px 0;
    padding: 0;
}

.current-listing-images ul li {
    position: relative;
    width: 100%;
    margin: 0 0 10px 0;
    background-color: #f9f9f9;
    overflow: auto;
}

.current-listing-images ul li .image-name {
    width: 100%;
    padding: 0 70px 0 0;
    min-height: 60px;
    color: #d7d7d7;
    box-sizing: border-box;
}

.current-listing-images ul li .ajax-remove-item {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8494e;
    color: #fff;
    padding: 0 10px;
    cursor: pointer;
}

.current-listing-images ul li label {
    padding: 4px;
    font-size: 14px;
    font-weight: normal;
    color: #3B3B3B;
}

.current-listing-images ul li input {
    margin-right: 4px;
}

.current-listing-images ul li img {
    float: left;
    height: 60px;
    width: auto;
    margin: 0 10px 0 0;
}

.loading {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    display: none;
    font-size: 12px;
    color: #111111;
}

.cancel-edit {
    float: right;
    text-decoration: underline;
    display: inline-block;
}

.view-list {
    float: right;
    margin: 15px;
}

.delete-list {
    float: right;
    margin: 15px;
}

.load-more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 20px 0;
}

.load-more span {
    width: 100%;
    max-width: 200px;
    padding: 6px 20px;
    background-color: #12C196;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
}

.listing-list .loading {
    margin: 20px 0;
}

.value-input {
    position: relative;
}

.value-input input {
    width: 100px;
    height: 40px;
    padding: 0 15px;
}

.value-input span {
    position: absolute;
    left: 5px;
    top: 36px;
}

.trade-btn {
    margin: 10px 10px 10px 0;
    background-color: #262B34;
    border-color: #262B34;
    color: #FFFFFF;
    padding: 8px 18px;
    font-size: 16px;
}

.trade-btn:hover,
.trade-btn:focus {
    background-color: #12C196;
    border-color: #12C196;
    color: #262B34;
}

.pdf-btn {
    display: none !important;
    margin: 10px 10px 10px 0;
    background-color: #262B34;
    border-color: #262B34;
    color: #FFFFFF;
    padding: 8px 18px;
    font-size: 16px;
}

.pdf-btn:hover,
.pdf-btn:focus {
    background-color: #12C196;
    border-color: #12C196;
    color: #262B34;
}

.listing-btn {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 16px;
    display: table-cell;
    vertical-align: middle;
    padding: 0;
}

.listing-btn:hover,
.listing-btn:focus {}

.listing-btn#add {
    background-color: #12C196;
    border-color: #12C196;
    color: #262B34;
    font-size: 14px;
    padding: 2px;
    width: 24px;
}

.trade-box {
    display: none;
    margin: 0 0 30px 0;
    background-color: #fff;
}

.trade-box .image {
    display: inline-block;
    width: auto;
    height: 60px;
}

.trade-box .image img {
    width: auto;
    height: 60px;
}

.trade-box input[type="checkbox"] {
    top: 20px;
}

.trade-box .checkbox {
    margin-bottom: 20px;
}

.trade-box .checkbox span {
    padding: 0 0 0 4px;
}

.trade-box .checkbox label {
    display: flex;
    min-height: 60px;
    align-items: center;
    padding-left: 30px;
    background-color: #f9f9f9;
}

.trade-box .checkbox label:hover {
    background-color: #e0f1fe;
}

.trade-list {
    margin-bottom: 30px;
}

.trade-list p {
    margin: 0 0 15px 0;
    color: #333;
}

.trade-list .no-image::after {
    font-size: 40px;
}

.js-errors {
    display: none;
    list-style: none;
    margin: 10px 0 0 0;
    padding: 10px;
    background-color: #f93337;
    color: #fff;
}

.js-errors li {
    padding: 3px 0;
}

.trade-box .btn-success {
    padding: 8px 20px;
    font-size: 18px;
}

#register-btn {
    background-color: #449d44;
    border-color: #398439;
}

.login-form label {
    color: #333 !important;
    font-size: 20px !important;
    padding: 0;
}

.login-form h1,
.register-form h1 {
    color: #12C196;
    font-size: 34px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
    letter-spacing: 1px;
}

.forget-page .form-content {
    max-width: 700px;
}

.forget-page .form-content h1 {
    color: #262B34;
    font-size: 34px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
    letter-spacing: 1px;
}

.login-form input,
.register-form input,
.forget-page input {
    height: 60px;
    background-color: #E3E3E3;
    border: none;
    box-shadow: none;
    border-radius: 0;
}

.login-form input[type="submit"],
.register-form input[type="submit"],
.forget-page input[type="submit"] {
    margin: 10px auto 0 auto;
    font-size: 22px !important;
    color: #fff !important;
    font-weight: bold;
    text-transform: uppercase;
}

.login-form a,
.register-form a {
    color: #5096E5;
    text-align: center;
    font-size: 17px;
}

.login-form a.create-account {
    background-color: #262B34;
    font-size: 22px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.no-account {
    color: #333 !important;
    font-size: 20px !important;
}

.no-account a {
    text-decoration: underline;
}



label.error {
    margin-top: 10px;
    border-radius: 25px;
    background-color: #f93337;
    color: #fff;
    padding: 2px 8px;
    font-size: 14px;
    font-weight: normal;
    transition: 1s;
}

.label-center {
    text-align: center;
    margin-bottom: 30px;
}

.label-center-raffle {
    text-align: center;
}

.navbar-brand {
    font-weight: bold;
    color: #333 !important;
    font-size: 20px;
    font-family: Arial;
    padding: 0;
}

.navbar-brand:hover {
    color: #333 !important;
    text-decoration: underline;
}

.footer-menu {
    list-style: none;
    margin: 0 0 10px 0;
    padding: 0;
    overflow: auto;
}

.footer-menu li {
    float: left;
    margin: 3px 8px 3px 0;
}

.hide-me {
    display: none;
}

.buttons-custom {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    list-style: none;
    margin: 40px 0;
    padding: 0;
}

.buttons-custom li {
    margin: 0 30px 0 0;
}

.buttons-custom li a {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00c49a;
    color: #404a5d;
    padding: 16px 40px;
    font-size: 22px;
    font-weight: bold;
}

.buttons-custom li a span {
    padding: 0 0 0 10px;
    color: #bb000b;
}

.buttons-custom li .cross-text {
    text-decoration: line-through;
    color: #444;
}

.buttons-custom li:last-child a {
    background-color: #00c49a;
    color: #333333;
}

@media(max-width: 767px) {
    .buttons-custom li a {
        padding: 15px 30px;
        height: 50px;
    }
}

.listing-right .product-likes {}

.listing-right .product-likes i {
    font-size: 28px;
    padding: 0 2px 0 0;
    color: #ddd;
}

.listing-right .product-likes .vote-info {
    padding: 0 0 0 6px;
}

.listing-right .product-likes i:hover,
.listing-left .product-likes i.active {
    color: #12C196;
}

input[type=submit].btn-primary {
    background-color: #12C196;
    border-color: #12C196;
    color: #1F252E;
    font-size: 24px;
    display: table-cell;
    vertical-align: middle;
    text-transform: uppercase;
    font-weight: bold;
    height: 60px;
}

.no-account,
.no-account a {
    font-weight: bold;
    font-size: 20px;
    font-family: Arial;
    text-align: center;
    color: #fff !important;
}

.empty-list p {
    color: #fff !important;
    font-weight: bold;
    font-size: 20px;
    font-family: Arial;
}

.empty-list p a {
    color: #09C297;
    font-weight: bold;
    font-size: 20px;
}

.my-listings {
    width: 100%;
    margin: 0 0 0 0;
}

#myInput::placeholder {
    color: #999999;
    font-family: 'Roboto', sans-serif;
}

.inventory {
    padding: 10px 0;
    background-color: transparent;
    border-radius: 2px;
}

.inventory-head {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.inventory-head li {
    padding: 10px;
    font-size: 14px;
    text-align: left;
    color: rgba(255, 255, 255, 0.67);
}

.inventory-image {
    width: 15%;
}

.inventory-name {
    width: 25%;
}

.inventory-brand {
    width: 10%;
}

.inventory-qty {
    width: 8%;
}

.inventory-status {
    width: 10%;
}

.inventory-retail-price {
    width: 10%;
}

.inventory-action {
    width: 5%;
}

.inventory-action p {
    padding: 0 !important;
    font-size: 20px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
}

.inventory-row {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 10px 0;
    border-bottom: 1px solid #19202A;
    background-color: rgba(52, 61, 75, 0.9);
    color: #ffffff;
}

.inventory-row:first-of-type {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.inventory-row:hover {
    background-color: rgba(18, 193, 150, 0.5);
}

.inventory-row:last-child {
    border: 0;
}

.inventory-row p {
    padding: 5px 0 0 0;
    color: #FFFFFF;
}

.inventory-row p strong {
    font-weight: 400;
}

.inventory-row li {
    padding: 10px;
}

.inventory-row .inventory-name {
    text-align: left;
}

.inventory-row .inventory-name .image {
    float: left;
    margin: 0 15px 0 0;
}

.inventory-row .inventory-name a {
    color: #ffffff;
}

.inventory-row .inventory-name .image a {
    display: flex;
    width: 80px;
    height: 60px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    box-sizing: content-box;
    overflow: hidden;
    justify-content: center;
}

.inventory-row .inventory-name .image img {
    display: block;
    height: 100%;
    max-height: 60px;
    max-width: 80px;
}

.inventory-image>div.image>a {
    margin: auto;
}

.inventory-row .inventory-image .image {
    margin: 10px;
}

.inventory-row .inventory-image .image a {
    display: block;
    width: 80px;
    height: 60px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    box-sizing: content-box;
    overflow: hidden;
    justify-content: center;
}

.inventory-row .inventory-image .image img {
    display: block;
    height: 100%;
    max-height: 60px;
    max-width: 80px;
    margin: auto;
}

.inventory-row .inventory-name h3 {
    font-size: 20px;
    margin: 4px 0;
}

.inventory-row .inventory-name h3 a {
    font-size: 20px;
    font-family: "SofiaProSoftW01-Regular", sans-serif;
    font-weight: 200;
    color: #FFFFFF;
}

.inventory-action .actions {
    display: none;
    position: absolute;
    z-index: 1;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px 10px;
}

.inventory-action .actions a {
    display: block;
    padding: 2px 0;
    width: 100%;
    box-sizing: border-box;
    font-size: 15px;
    color: #444;
}

.csv-import {
    float: right;
    margin: 12px 0 0 0;
}

.csv-import a {
    padding: 5px 10px;
    background-color: #449d44;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
}

.csv-import a i {
    padding: 0 0 0 3px;
}

.listed {
    display: none;
    margin: 10px 0 0 0;
}

/* Styles for wrapping the search box */

#button {
    margin: 10px;
}

.vertical-text {}

.input-group {
    width: 50%;
    margin: 10px;
}

/* Bootstrap 4 text input with search icon */

.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    /*position: absolute;*/
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    /*text-align: center;*/
    pointer-events: none;
    color: #aaa;
}

.search-fild input {
    height: 36px;
    width: auto;
    color: #000000;
    font-size: 20px;
    font-weight: 100;
    line-height: 35px;
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 2px solid black;
}

.search-fild button {
    padding: 4px 10px;
}

.user_id {
    float: left;
    margin: 0;
    padding: 20px 20px;
    font-weight: bold;
    text-align: center;
}

.user_id span {
    font-size: 18px;
}

.user_id i {
    padding: 0 0 0 4px;
    font-size: 20px;
    color: #FFFFFF;
}

.register_print {
    margin: 30px 0;
    text-align: center;
}

.register_print p {
    color: #333333;
}

.register_print a {
    margin: 0;
    padding: 0;
    display: inline-block;
    text-decoration: underline;
}

.social_login {
    margin: 0px 0 20px 0;
}

.social_login p {
    color: #333;
    font-size: 18px;
    text-align: left;
    /*font-weight: bold;*/
}

.social_login ul {
    padding-left: 20px;
}

/*.social_login ul li{*/

/*    margin: 0 0 10px 0;*/

/*    background-color:#333333;*/

/*    border-radius: 5px;*/

/*    overflow:hidden;*/

/*}*/

.social_login ul li a {
    margin: 0;
    display: block;
    padding: 12px 10px;
    text-align: center;
    color: #fff !important;
    font-size: 18px !important;
    font-weight: 500;
}

.social_login ul li a i {
    padding: 0 0 0 4px;
    font-size: 22px;
}

.fb_btn {
    background-color: #3b5998;
}

.twitter_btn {
    background-color: #00acee;
}

.instagram_btn {
    background: #f09433;
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
}

.google_btn {
    background-color: #DB4437;
}

.social_login ul li a:hover {
    text-decoration: none;
    opacity: 0.9;
}

.home_header {
    margin: 20px 0;
    text-align: center;
}

.home_header p {
    color: #ffffff !important;
    margin: 25px 0;
    font-size: 24px;
    line-height: 120%;
}

.home_header p img {
    width: 100%;
    max-width: 400px;
    padding: 30px 0 0 0;
}

.home_header a {
    color: #dddddd;
}

.search-mod {
    padding: 10px 10px;
}

.search-mod select {
    width: 120px;
    display: inline;
}

.search-mod strong {
    display: inline;
}

.require {
    color: #ff0000;
}

.close {
    color: #555E6E !important;
    opacity: 1 !important;
    font-size: 28px;
}

.modal-dialog {
    margin: 20px !important;
}

.hidden {
    display: none;
}

.inputcontainer {
    position: relative;
}

.icon-container {
    position: absolute;
    right: 10px;
    top: 32px;
}

.icon-container-market {
    position: absolute;
    left: 220px;
    top: 8px;
}

@media only screen and (min-width: 1200px) {
    .icon-container-market {
        left: 450px;
    }
}

.loader {
    position: relative;
    height: 20px;
    width: 20px;
    display: inline-block;
    animation: around 5.4s infinite;
}

.loader-big {
    position: relative;
    height: 200px;
    width: 200px;
    margin: auto;
    display: block;
    animation: around 10.4s infinite;
}

.loader-div {
    background-color: rgba(200, 200, 200, 0.3);
    margin: auto;
    width: 100%;
    padding: 20%;
}

@media only screen and (max-width: 400px) {
    .loader-big {
        height: 100px;
        width: 100px;
    }
}

.loader-big::after,
.loader-big::before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-color: #66ccff #66ccff transparent transparent;
    border-style: solid;
    border-radius: 200px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    animation: around 1.7s ease-in-out infinite;
}

@keyframes around {
    0% {
        transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg)
    }
}

.loader::after,
.loader::before {
    content: "";
    background: white;
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-color: #12C196 #12C196 transparent transparent;
    border-style: solid;
    border-radius: 20px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    animation: around 0.7s ease-in-out infinite;
}

.loader::after {
    animation: around 0.7s ease-in-out 0.1s infinite;
    background: transparent;
}

.swal2-popup {
    font-size: 1.6rem !important;
}

@media (min-width: 768px) {
    .modal-dialog {
        margin: 160px auto !important;
    }
}

@media only screen and (max-width: 1200px) {
    /*table, thead, tbody, th, td, tr {*/
    /*    display: block;*/
    /*    font-size: 18px;*/
    /*    text-align: center;*/
    /*    font-family: 'Montserrat', sans-serif;*/
    /*    font-weight: 200;*/
    /*    !*color: #2C2C2C;*!*/
    /*}*/
    .btn-group {
        display: grid;
    }
    .create-buttons {
        border-radius: 5px !important;
    }
    thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    tr {
        border: 1px solid #ccc;
        border-radius: 10px;
    }
    /*th{*/
    /*    width: 100%;*/
    /*}*/
    /*td {*/
    /*    border: none;*/
    /*    !*border-bottom: 1px solid #eee;*!*/
    /*    position: relative;*/
    /*    padding-left: 200px;*/
    /*    margin-left: 150px;*/
    /*}*/
    /*Label the data*/
    th:nth-of-type(1):before {
        content: "Product Title";
    }
    th:nth-of-type(2):before {
        content: "Type";
    }
    th:nth-of-type(3):before {
        content: "Size";
    }
    th:nth-of-type(4):before {
        content: "Condition";
    }
    th:nth-of-type(5):before {
        content: "Action";
    }
}

.search_filter {
    margin-top: 20px;
    margin-bottom: 20px;
}

.search_filter a {
    display: flex;
    color: #2C2C2C;
}

.search_filter a:hover {
    /*display: inline;*/
    color: #2C2C2C !important;
}

.search_filter .btn-info:focus {
    /*display: inline;*/
    outline: 0;
}

.search_filter .btn-info:active {
    /*display: inline;*/
    border-color: transparent;
    background-color: transparent;
    color: #2C2C2C !important;
}

.search_filter .btn-info {
    /*display: inline;*/
    border-color: transparent;
    background-color: transparent;
    color: #2C2C2C !important;
}

.listing-list {
    width: 100%;
    padding: 0 20px;
    padding: 50px 15px 0;
}

@media (max-width: 767px) {
    .listing-list {
        padding: 0 2%;
    }
}

.listing-list .container {
    width: 95%;
    /*background-color: white;*/
}

input[type="checkbox"] {
    display: none;
}

input[type="checkbox"]+span {
    display: inline;
    padding: 2px 10px;
    border-radius: 4px;
}

input[type="checkbox"]:checked+span {
    /*border:1px solid #2C2C2C;*/
    color: #2C2C2C;
    font-weight: bold;
}

.search_filter {
    margin-right: -8px;
}

.col-md-10 {
    margin-top: 20px;
}

.selector {
    padding: 0 !important;
}

.tools {
    display: block;
}

.tools h5 {
    float: right;
}

#sort {
    display: flex;
    float: right;
    width: auto;
}

#clearFilter {
    margin-right: 10px;
    display: inline-block;
    margin-top: 10px;
    width: auto;
}

.social-media {
    width: 100%;
    max-width: 190px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 30px auto 0 auto !important;
    padding: 0 !important;
    list-style: none !important;
}

.social-media li {
    padding: 0 1.4rem;
    margin: 0 0 0 0 !important;
    max-width: 100% !important;
    width: auto !important;
}

.social-media li a {
    font-size: 4rem;
}

@media only screen and (max-width: 1200px) {
    .col-sm-2 {
        min-width: 250px;
        width: 100%;
    }
    .col-md-10 {
        width: 100%;
    }
    .tools input {
        width: 110px;
    }
}

/*Calendar*/

.calendar-card {
    text-align: center;
    width: 100%;
    max-width: 600px;
    min-width: 250px;
    min-height: 300px;
    background-color: #FFFFFF;
    border-radius: 20px 20px 0px 20px;
    -moz-border-radius: 20px 20px 0px 20px;
    -webkit-border-radius: 20px 20px 0px 20px;
    border: 40px #FFFFFF;
    box-shadow: 20px 20px 0px 5px rgba(38, 43, 52, 0.7);
    -moz-box-shadow: 20px 20px 0px 5px rgba(38, 43, 52, 0.7);
    -webkit-box-shadow: 20px 20px 0px 5px rgba(38, 43, 52, 0.7);
    position: relative;
    margin: auto;
    background: #FFFFFF;
    overflow: hidden;
}

.calendar-card img {
    padding-top: 80px;
}

/*.calendar-card ,.corner*/

/*{*/

/*    -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;*/

/*    -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;*/

/*    box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 20px rgba(0, 0, 0, 0.1) inset;*/

/*}*/

.calendar-card:before,
.calendar-card:after {
    content: "";
    position: absolute;
    z-index: -1;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    top: 0;
    bottom: 0;
    left: 10px;
    right: 10px;
    -moz-border-radius: 100px / 10px;
    border-radius: 100px / 10px;
}

.calendar-card:after {
    right: 10px;
    left: auto;
    -webkit-transform: skew(8deg) rotate(3deg);
    -moz-transform: skew(8deg) rotate(3deg);
    -ms-transform: skew(8deg) rotate(3deg);
    -o-transform: skew(8deg) rotate(3deg);
    transform: skew(8deg) rotate(3deg);
}

.corner {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    height: 40px;
    padding: 0 10px;
    /*transform: rotate(45deg);*/
    background: #000000;
}

.new {
    position: absolute;
    right: 20px;
    bottom: 1px;
}

/*.raffle-list ul li {*/

/*    width: 40% !important;*/

/*    max-width: calc(33.33333% - 40px) !important;*/

/*    margin: 0 30px 30px 0;*/

/*}*/

.raffle-list ul li {
    display: inline-block;
    width: 40% !important;
    max-width: calc(33.33333% - 40px) !important;
    margin: 0 30px 30px 0;
}

.triangle {
    position: absolute;
    top: -5px;
    right: -24px;
    width: 70px;
    height: 35px;
    transform: rotate(45deg);
    background: green;
}

.format ul li {
    width: auto;
    margin: 50px !important;
}

.listing-search::placeholder {
    color: #D8D9DB;
}

.listing-search {
    width: 480px;
    color: #000000;
    font-size: 25px;
    font-weight: 100;
    line-height: 35px;
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid #333333;
}

.condition-list {
    padding: 0 10px 0 10px !important;
    white-space: normal !important;
    display: block !important;
    text-align: left !important;
    line-height: 120%;
}

.condition-list span {
    padding: 0 !important;
}

.card-body {
    text-align: center;
    padding-bottom: 20px;
}

.card-body {
    text-align: center;
}

.rounded-rectangle-3 {
    padding: 0 25px 40px 25px;
    border-radius: 5px;
    width: 100%;
    /*background-color: #FFFFFF;*/
}

.enter-search-filter {
    height: 26px;
    width: 363px;
    color: #D8D9DB;
    /*font-family: "Sofia Pro";*/
    font-size: 28px;
    font-weight: 300;
    line-height: 35px;
}

.rectangle-3-copy-6 {
    height: 3px;
    width: 563px;
    background-color: #424E62;
}

.filter {
    height: 35px;
    width: 162px;
    background-color: #E5E5E7;
}



.modal .modal-content .modal-header {
    border: none;
    padding: 30px 30px 25px;
}

.modal .modal-content .modal-header .modal-title {
    color: #12C196;
    font-weight: bold;
    font-size: 34px;
}

.modal-body h4 {
    color: #6F7785;
}


#typeValue,
#sizeValue,
#conditionValue,
#receiptValue,

.login-form {
    position: relative;
    overflow: auto;
}

.login-form .checkbox {
    display: none;
}

.login-form .btn-lg {
    float: left !important;
    width: 30% !important;
    margin: 5px 0 30px 0 !important;
}

.form-content>div:nth-child(5),
.form-content>div:nth-child(6) {
    position: absolute;
    bottom: 20px;
    right: 36px;
}

.login-form .create-account {
    float: right;
    width: calc(70% - 15px);
    margin: 5px 0 30px 15px;
}

.login-form>div:nth-child(4) {
    margin-top: 5px;
    position: absolute;
    bottom: 20px;
    left: 40px;
}

.totalBuy.slot-total-amount.total {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}

.line-hero {
    height: 2px;
    background-color: #00ca9f;
}

@media ( max-width: 1000px) {
    .listing-search {
        height: 36px;
        width: 100%;
        color: #000000;
        font-size: 18px;
        font-weight: 100;
        line-height: 35px;
        border: 0;
        outline: 0;
        background: transparent;
        border-bottom: 2px solid black;
    }
    .first-page {
        border-radius: 20px;
    }
    .raffle-list {
        justify-content: center;
    }
    .listing-view .listing-left {
        width: 100%;
        min-width: auto;
        margin-left: 0;
    }
    .listing-view .listing-left .current-image {
        height: auto;
    }
    .listing-view {
        display: block;
    }
    .inventory-head {
        display: none;
    }
    .inventory-row {
        flex-wrap: wrap;
    }
    .inventory-image {
        width: 100%;
    }
    .inventory-name {
        width: 100%;
    }
    .inventory-brand {
        width: 100%;
    }
    .inventory-qty {
        width: 100%;
    }
    .inventory-status {
        width: 100%;
    }
    .inventory-retail-price {
        width: 100%;
    }
    .inventory-action {
        position: relative;
        width: 100%;
    }
    .inventory-row li {
        text-align: left;
    }
    .inventory .hide-me {
        display: inline-block;
    }
    .inventory-row p {
        padding: 0;
        margin: 2px 0;
    }
    .inventory-row .inventory-name .image {
        float: none;
    }
    .user_id {
        color: #FFFFFF;
        float: left;
        padding: 14px;
    }
    .listing-list ul li .image a {
        border: 0;
    }
    .rounded-rectangle-3 {
        padding: 1px 20px;
    }
    .inventory-row .inventory-image .image a {
        width: 100%;
        height: auto;
    }
    .inventory-row .inventory-image .image img {
        max-height: 100%;
        max-width: 100%;
        height: auto;
    }
    .listing-list ul li .image .no-image {
        min-height: 200px;
    }
    .listing-view .listing-left .current-image {
        box-shadow: 10px 10px 0px 0px rgba(38, 43, 52, 0.7);
        -moz-box-shadow: 10px 10px 0px 0px rgba(38, 43, 52, 0.7);
        -webkit-box-shadow: 10px 10px 0px 0px rgba(38, 43, 52, 0.7);
    }
    .inventory-row .inventory-image .image .no-image {
        min-height: 200px;
    }
    .icon-container-market {
        position: absolute;
        left: 90%;
        top: 8px;
    }
}

.product-filter {
    padding-top: 10px;
    padding-bottom: 10px;
}

.product-filter select,
option {
    margin-right: 10px;
    min-width: 130px;
    max-width: 250px;
}

.results {
    margin-right: 10px;
    color: #ABABAB;
}

.remove-filter {
    padding-bottom: 10px;
}

.drop-btn:hover {
    color: #12C196 !important;
    text-decoration: none;
    cursor: pointer;
}

.drop-btn-container {
    padding-top: 15px;
    padding-bottom: 15px;
}

#collapseFilter {
    padding-bottom: 0;
    padding-top: 0;
}

.swal-modal {
    width: 90%;
    max-width: 500px;
    background-color: #FFFFFF;
    border-radius: 20px 20px 0px 20px;
    -moz-border-radius: 20px 20px 0px 20px;
    -webkit-border-radius: 20px 20px 0px 20px;
    box-shadow: 10px 10px 0px 5px rgba(38, 43, 52, 0.7);
    -moz-box-shadow: 10px 10px 0px 5px rgba(38, 43, 52, 0.7);
    -webkit-box-shadow: 10px 10px 0px 5px rgba(38, 43, 52, 0.7);
}

.modal-content {
    /*width: 90%;*/
    /*max-width: 500px;*/
    background-color: #FFFFFF;
    border-radius: 20px 20px 0px 20px;
    -moz-border-radius: 20px 20px 0px 20px;
    -webkit-border-radius: 20px 20px 0px 20px;
    border: 27px #FFFFFF;
    box-shadow: 10px 10px 0px 5px rgba(38, 43, 52, 0.7);
    -moz-box-shadow: 10px 10px 0px 5px rgba(38, 43, 52, 0.7);
    -webkit-box-shadow: 10px 10px 0px 5px rgba(38, 43, 52, 0.7);
}

.swal-title {
    font-weight: 100;
    font-size: 20px;
}

.swal-button--cancel,
.swal-button--catch {
    background-color: #66ccff;
    color: #FFFFFF;
    border-radius: 0;
    height: 50px;
    font-weight: 600;
    font-family: sans-serif;
    -webkit-transition-duration: 0.4s;
    /* Safari */
    transition-duration: 0.4s;
    padding: 0 24px;
}

.swal-button:hover {
    background-color: #FFFFFF !important;
    color: #66ccff !important;
}

.modal-body {
    text-align: center;
}

#plus {
    font-size: 70px;
    padding: 10px;
}

#minus {
    font-size: 70px;
    padding: 10px;
}

.ticket-value {
    padding: 10px;
    font-size: 40px !important;
}

#ticket-value {
    min-height: 80px;
    text-align: center;
}

.user-ticket,
.user-ticket-value {
    font-size: 50px !important;
}

.user-ticket {
    display: inline-flex;
}

#ticket {
    margin-top: 10px;
    margin-bottom: 10px;
}

.card-title h4 {
    font-size: 25px;
}

.form-content {
    width: 100% !important;
    max-width: 800px;
    margin: 0 auto !important;
    background-color: #FFFFFF;
    border-radius: 20px 20px 0px 20px !important;
    -moz-border-radius: 20px 20px 0px 20px !important;
    -webkit-border-radius: 20px 20px 0px 20px !important;
    border: 20px solid #FFFFFF;
    box-shadow: 10px 10px 0px 5px rgba(38, 43, 52, 0.7) !important;
    -moz-box-shadow: 10px 10px 0px 5px rgba(38, 43, 52, 0.7) !important;
    -webkit-box-shadow: 10px 10px 0px 5px rgba(38, 43, 52, 0.7) !important;
}

.create-title {
    font-family: sans-serif;
    color: #66ccff;
}

.expandBilling.title-hero {
    font-family: 'Roboto', sans-serif;
    color: #404B5E;
    font-weight: 300;
}

.expandSquare.title-hero {
    font-family: 'Roboto', sans-serif;
    color: #404B5E;
    font-weight: 300;
}

@media (max-width: 767px) {
    .create-title {
        font-size: 22px;
        font-weight: bold;
        margin: 0 0 20px;
    }
}

.create-text {
    color: #414C5E;
    font-family: sans-serif;
    font-weight: 100;
}

.next-button {
    margin-bottom: 10px;
    margin-top: 10px;
    /*width: 160px;*/
    min-width: 160px;
    height: 50px;
    border-radius: 0;
    background-color: #12C196;
    color: #414C5E;
}

.next-button:hover {
    border-radius: 0;
    background-color: #414C5E;
    color: #12C196;
}

.button-placeholder {
    padding-top: 10px;
    text-align: center;
}

#listForm select {
    height: 60px;
}

.create-input {
    color: #6F7785;
    border-radius: 0;
    width: 100%;
    padding: 0 15px;
    background: #F2F2F2;
    border: 1px solid #E7E7E7;
    font-size: 16px;
    line-height: 40px;
    height: 60px;
    opacity: 1;
}

.creating-shoes-page-1 p,
.creating-shoes-page-2 p,
.creating-shoes-page-3 p,
.creating-clothe-page-1 p,
.creating-clothe-page-2 p,
.create-new-shoe p,
.creating-new-clothe p,
.creating-new-other p {
    color: #3B3B3B;
}

.create-spacer {
    margin: 20px 0 20px 0;
    height: 5px;
    width: 100%;
    background-color: #555;
}

.autocomplete-suggestion img {
    padding-top: 6px;
}

.autocomplete-suggestion div {
    margin-left: 120px;
}

@media ( max-width: 600px) {
    .login-form .btn-lg {
        width: 100% !important;
        margin: 10px 0 10px 0 !important;
    }
    .login-form .create-account {
        width: 100% !important;
        margin: 0 0 40px 0 !important;
    }
}

.calendar-raffle .raffle-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.calendar-raffle .raffle-list .raffle-block {
    width: calc(25% - 10px);
    margin: 0 5px 10px 5px;
    cursor: pointer;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.raffle-block h4 {
    color: white;
    font-size: 16px;
}

.raffle-list .raffle-block img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 100%;
    object-fit: contain;
}

.calendar-raffle .raffle-list .raffle-block.selected {
    border: 5px solid #12C196;
    box-shadow: 5px 15px 0px 2px rgba(38, 43, 52, 0.7);
}

.card-img-top {
    margin: auto;
}

.calendar-raffle .raffle-product .product-image {
    margin: auto;
    display: grid;
    /*min-height: 400px;*/
    width: 100%;
    height: 100%;
    /*max-width: 450px;*/
    /*margin: 0 80px 50px 0;*/
    background-color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 10px 10px 0px 5px rgba(38, 43, 52, 0.7);
}

.empty-raffle h1 {
    color: #12C196;
}

/*.raffle-modal-body h4{*/

/*    color: #2C2C2C;*/

/*}*/

@media (max-width: 991px) {
    .calendar-raffle .raffle-product .product-image {
        box-shadow: 10px 10px 0 0 rgba(38, 43, 52, 0.7);
    }
    .calendar-raffle .raffle-product {
        margin-top: 50px;
    }
}

.calendar-raffle .raffle-product .product-image img {
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.drawing-block {
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: normal;
}

.drawing-block .drawing {
    background-color: #1F252E;
    padding-top: 9px;
    margin-bottom: 13px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;
    width: calc(23% - 6px);
    /*padding-bottom: 40px;*/
    margin-right: 10px;
}

.drawing-block .drawing-btn {
    vertical-align: middle;
    text-align: center;
    background: #09C297;
    height: 56px;
    border-bottom-right-radius: 10px;
}

.drawing-btn h3 {
    color: rgb(31, 37, 46);
    ;
    padding-top: 16px;
    font-weight: 700;
    font-size: 16px;
}

.drawing-pemium-btn {
    background-color: #09C297;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: 20px;
    position: relative;
}

.drawing-pemium-btn h3 {
    color: rgb(31, 37, 46);
    ;
    font-weight: 700;
    font-size: 25px;
    padding: 30px;
    text-align: center;
}

.drawing-pemium-btn .drawing-button {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: none;
    border: none;
}

@media(max-width: 915px) {
    .drawing-block .drawing {
        width: calc(33.3333333% - 11px);
        /*padding-bottom: 40px;*/
    }
    .drawing-btn h3 {
        color: rgb(31, 37, 46);
        ;
        padding-top: 10px;
        font-weight: 700;
        font-size: 17px;
    }
    .calendar-raffle .listing-right h1 {
        font-size: 40px;
    }
}

.drawing-block .drawing h5 {
    color: #fff;
    text-align: center;
    font-size: 24px;
    margin: 0;
}

.raffle-format {
    font-size: 22px;
    color: white;
    margin-bottom: 10px;
}

.drawing-block .drawing h4 {
    height: 24px;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 18px;
    color: #fff;
    text-align: center;
}

.drawing-block .drawing button {
    height: 100%;
    background: none;
    color: #1F252E;
    font-weight: bold;
    text-align: center;
    /*background-color: #12C196;*/
    /*height: 44px;*/
    font-size: 17px;
    border: none;
    width: 100%;
    border-bottom-right-radius: 10px;
    position: absolute;
    left: 0;
    bottom: 0
}

.pagination a {
    color: #09C297;
    display: inline-block;
    padding: 0 10px;
    cursor: pointer;
}

.product-detail h1 {
    color: white;
}

.border {
    border-style: solid;
    border-color: #12C196;
    border-width: thick;
}

.arrow_size {
    font-size: 25px;
}

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 160;
    top: 0;
    right: 0;
    background-color: #424F63;
    overflow-x: hidden;
    text-align: left;
    padding: 20px 0;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

.sidenav .logo {
    width: 160px;
}

.menu-action-btn {
    text-align: right;
}

.menu-action-btn .menu-buttons {
    display: inline-block;
    background-color: #12C196;
    text-transform: uppercase;
    font-size: 22px;
    color: #414C5E;
    text-decoration: none;
    padding: 10px 15px;
    font-weight: bold;
    width: 119px;
    height: 40px;
    text-align: center;
}

.autocomplete-suggestions {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    background-color: #FFFFFF;
    border-radius: 0 0 20px 20px;
    -moz-border-radius: 0 0 20px 20px;
    -webkit-border-radius: 0 0 20px 20px;
    border: 10px solid #FFFFFF;
    box-shadow: 7px 7px 0px 7px rgba(38, 43, 52, 0.7);
    -moz-box-shadow: 5px 5px 0px 7px rgba(38, 43, 52, 0.7);
    -webkit-box-shadow: 5px 5px 0px 7px rgba(38, 43, 52, 0.7);
}


.menu-action-btn .usd-button {
    visibility: hidden;
    border: none;
    height: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-size: 25px;
    color: #00C49A;
}

.menu-action-btn .usd-button:hover {
    text-decoration: none;
}

@media(max-width: 767px) {
    .menu-action-btn .usd-button {
        visibility: visible;
    }
    .calendar-raffle .raffle-list .raffle-block {
        width: calc(50% - 20px);
        margin: 10px 10px 10px 10px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        -webkit-box-shadow: 6px 6px 0px 0px rgba(38, 46, 58, 1);
        -moz-box-shadow: 6px 6px 0px 0px rgba(38, 46, 58, 1);
        box-shadow: 6px 6px 0px 0px rgba(38, 46, 58, 1);
    }
    .drawing-block {
        margin: 0;
    }
    .raffle-block h4 {
        color: white;
        font-size: 14px;
    }
    .menu-action-btn .menu-buttons {
        display: none !important;
    }
}


@media (max-width: 576px) {
    .menu-action-btn .menu-buttons {
        font-size: 18px;
        padding: 10px;
        width: 80px;
    }
    #open-menu {
        background-size: 30px;
        width: 30px;
        height: 40px;
        margin-left: 20px;
    }
    .menu-action-btn .search-button {
        margin: 0 -10px 0 5px;
    }
}

.sidenav #close-btn {
    background-color: transparent;
    background: url("../assets/menu-outline-blue.png");
    background-repeat: no-repeat;
    background-size: 32px;
    background-position: center center;
    border: none;
    width: 32px;
    height: 32px;
    float: right;
}

.sidenav ul {
    list-style-type: none;
    padding: 0;
    text-align: right;
}

.black-overlay {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(44, 52, 67, .8);
}

.sidenav ul li a,
.sidenav ul li .submenu {
    color: #12C196;
    font-size: 22px;
    padding: 10px 0;
    text-decoration: none;
    display: block;
    transition: 0.3s;
    cursor: pointer;
}

.sidenav ul li .submenu:hover {
    color: #f1f1f1;
}

.sidenav ul li .children a {
    padding: 5px 0 5px 0;
    font-size: 20px;
    color: #0f9b78;
}

.carousel {
    height: 450px;
    border-radius: 40px;
    box-shadow: 20px 20px 0px 5px rgba(38, 43, 52, 0.7);
    margin: 50px 0 80px 0;
}

.carousel-inner {
    border-radius: 40px;
}

.carousel-inner a {
    display: block;
    width: 100%;
    height: 100%;
}

.carousel-content.caption-one {
    background: url("https://sneaker.imgix.net/https%3A%2F%2Fs3.amazonaws.com%2Ftrusted-trader-bucket%2Ftrust-trade%2F20200113215740_1.png?auto=compress&bg=FFFFFF&fit=fill&h=1080&ixlib=php-3.1.0&w=1920&s=b70245467d8ea705bee1df2a4aa1c9e8");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 450px;
    border-radius: 40px;
}

.carousel-content.caption-two {
    background: url("https://sneaker.imgix.net/https%3A%2F%2Fs3.amazonaws.com%2Ftrusted-trader-bucket%2Ftrust-trade%2F20200114003543_1.png?auto=compress&bg=FFFFFF&fit=fill&h=1080&ixlib=php-3.1.0&w=1920&s=515cdaa2a55c72a7dc434b0c4e33e800");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 450px;
    border-radius: 40px;
}

.carousel-content.caption-three {
    background: url("https://sneaker.imgix.net/https%3A%2F%2Fs3.amazonaws.com%2Ftrusted-trader-bucket%2Ftrust-trade%2F20200113183525_1.png?auto=compress&bg=FFFFFF&fit=fill&h=1080&ixlib=php-3.1.0&w=1920&s=74abf738d7acceef2a1edfe3fc77edfa");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 450px;
    border-radius: 40px;
}

.carousel-indicators {
    margin: 30px auto;
    position: relative;
    left: auto;
    bottom: auto;
    width: 100%;
}

.carousel-indicators li {
    width: 27px;
    height: 27px;
    background-color: #0D1422;
    border-radius: 50px;
    border: none;
    margin: 0 5px;
}

.carousel-indicators .active {
    background-color: #12C196;
    width: 27px;
    height: 27px;
    margin: 0 5px;
}

@media(max-width: 767px) {
    .carousel-indicators li {
        width: 16px;
        height: 16px;
    }
    .carousel-indicators .active {
        width: 16px;
        height: 16px;
    }
}

.popular-container {
    position: relative;
    width: 100%;
    margin: 10px 0 10px 0;
}

.popular-container h2 {
    margin: 0 40px 0 0;
    font-size: 28px;
    font-weight: bold;
}

@media (max-width: 767px) {
    .popular-container h2 {
        font-size: 22px;
    }
}

.popular-container ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

.popular-container li {
    padding: 0 20px;
}

.header-banner .bx-viewport {
    background-color: #fff;
    border-radius: 30px;
    border-bottom-right-radius: 0;
}

.header-banner li .image {
    /*padding: 10px;*/
    transition: transform .5s ease;
    overflow: hidden;
}

.header-banner .bx-wrapper .bx-pager {
    position: relative !important;
    bottom: 0 !important;
    padding: 10px 0 0 0;
}

.header-banner .bx-wrapper {
    margin-bottom: 30px;
}

.header-banner .bx-wrapper .bx-pager.bx-default-pager a {
    width: 14px;
    height: 14px;
    background-color: #0D1422;
    border-radius: 14px;
    margin: 0 5px;
}

.header-banner .bx-wrapper .bx-pager.bx-default-pager a.active {
    background-color: #12C196;
}

.header-banner .bx-wrapper img {
    margin: auto;
    border-radius: 30px;
    border-bottom-right-radius: 0;
    width: 100%;
    object-fit: contain;
}

@media(max-width: 767px) {
    .header-banner .bx-wrapper .bx-pager.bx-default-pager a {
        width: 16px;
        height: 16px;
    }
    .header-banner .bx-wrapper .bx-pager.bx-default-pager a.active {
        width: 16px;
        height: 16px;
    }
    .header-banner .bx-viewport {
        border-radius: 10px;
        border-bottom-right-radius: 0;
    }
    .header-banner .bx-wrapper img {
        border-radius: 10px;
        border-bottom-right-radius: 0;
    }
}

.home-text {
    margin: 30px 0;
    display: flex;
    width: 100%;
    justify-content: center;
}

.home-text .home-left {
    width: 35%;
    display: flex;
    align-items: flex-start;
    padding: 0 20px 0 0;
}

.home-text .home-left h3 {
    color: #ffffff;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
}

.home-text .home-left ul {
    padding: 0 0 0 20px;
    font-size: 20px;
    color: #ffffff;
}

.home-text .home-left ul li {
    padding: 4px 0;
    font-weight: 400;
}

.home-text .home-right {
    width: 40%;
    display: flex;
    align-items: flex-start;
    padding: 15px 0 0 0;
}

.home-text .home-right img {
    display: block;
    max-width: 100%;
    box-shadow: 10px 10px 0px 5px rgba(38, 43, 52, 0.7);
    -moz-box-shadow: 10px 10px 0px 5px rgba(38, 43, 52, 0.7);
    -webkit-box-shadow: 10px 10px 0px 5px rgba(38, 43, 52, 0.7);
    border-radius: 10px 10px 0px 20px;
    -moz-border-radius: 10px 10px 0px 10px;
    -webkit-border-radius: 10px 10px 0px 10px;
}

.popular-container li .image {
    background-color: #fff;
    border-radius: 30px;
    border-bottom-right-radius: 0;
    transition: transform .5s ease;
    overflow: hidden;
}

.popular-container li .image a {
    display: flex;
    width: 100%;
    justify-content: center;
}

.modal-listing a img {
    width: 100%;
    max-width: 180px;
}

.modal-listing {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
}

.popular-container li .image img {
    width: auto;
    height: 250px;
    object-fit: contain;
}

.popular-container li .image:hover,
.popular-container .no-image:hover {
    transform: scale(1.05);
}

.popular-container li .no-zoom:hover {
    transform: scale(1) !important;
}

.popular-container .no-image {
    border-radius: 20px;
    border-bottom-right-radius: 0;
    transition: transform .5s ease;
}

.popular-container .info h4 {
    font-size: 16px;
    overflow-wrap: break-word;
    line-height: 120%;
    color: #ffffff;
}

.popular-container .info span {
    display: none;
    float: right;
    padding: 0 0 0 10px;
}

.popular-container .pagination {
    position: absolute;
    top: 0;
    right: 0;
    margin: 5px 0 0 0;
}

.popular-container .pagination a {
    padding: 0 15px;
}

.popular-container .bx-wrapper {
    /*padding: 20px 0 10px 0;*/
    box-shadow: none;
    border: 0;
    background: transparent;
    overflow: hidden;
    display: block;
    margin: 0 auto;
}

.popular-container .bx-viewport {
    overflow: visible !important;
}

.popular-container .bx-wrapper .bx-controls-direction a {
    margin-top: -46px;
    z-index: 999;
}

.popular-container .bx-wrapper .bx-prev {
    left: 0;
}

.popular-container .bx-wrapper .bx-next {
    right: 0;
}

.header-banner .bx-wrapper {
    padding: 20px 0 0 0;
    box-shadow: none;
    border: 0;
    background: transparent;
    overflow: hidden;
}

@media (max-width: 767px) {
    .carousel,
    .carousel-content.caption-one,
    .carousel-content.caption-two,
    .carousel-content.caption-three {
        height: 300px;
    }
    .popular-container li .image img {
        height: 100px;
    }
    .calendar-raffle .popular-container li .image img {
        height: 100%;
    }
    .calendar-raffle .popular-list .popular-container li .image img {
        height: 100px;
    }
    .popular-container li {
        padding: 0 10px;
    }
    .popular-container li .image {
        border-radius: 10px;
        border-bottom-right-radius: 0;
        width: initial;
    }
    .home-text {
        margin: -20px 0 30px 0;
    }
    .home-text .home-left ul {
        font-size: 16px;
    }
    .home-text .home-left {
        width: 60%;
        padding: 0 10px 0 0;
        align-items: flex-start;
    }
    .home-text .home-left h3 {
        font-size: 22px;
    }
    .home-text .home-right {
        align-items: flex-start;
        padding: 20px 0 0 0;
    }
    .home-text .home-right img {
        box-shadow: 5px 5px 0px 5px rgba(38, 43, 52, 0.7);
        -moz-box-shadow: 5px 5px 0px 5px rgba(38, 43, 52, 0.7);
        -webkit-box-shadow: 5px 5px 0px 5px rgba(38, 43, 52, 0.7);
        border-radius: 10px 10px 0px 10px;
        -moz-border-radius: 10px 10px 0px 10px;
        -webkit-border-radius: 10px 10px 0px 10px;
    }
}

.swal-button-container {
    vertical-align: middle;
}

.swal-footer {
    text-align: center;
}

.swal-button--cancel {
    color: #fff;
    background-color: #262B34;
    font-weight: bold;
    text-align: center;
    border-radius: 0;
}

.swal-button--confirm {
    height: 60px;
    color: #fff;
    background-color: #C13312;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 28px;
    text-align: center;
    border-radius: 0;
}

.sq-payment-form {
    background-color: #ffffff;
    margin: 0;
    border-radius: 10px;
}

.sq-payment-form #error {
    margin: 0 0 20px 0;
    padding: 0;
    list-style: none;
    text-align: left;
    border-radius: 4px;
}

.sq-payment-form #error li {
    padding: 2px 10px;
    background-color: #fee9e9;
}

.sq-payment-form .sq-button {
    background-color: #12C196;
}

.sq-payment-form {
    width: auto !important;
}

.sq-button {
    border: 0;
}

.checkout .expandBilling,
.checkout .expandSquare {
    cursor: pointer;
}

.checkout .expandBilling i,
.checkout .expandSquare i {
    float: right;
    color: #12C196;
    font-size: 24px;
}

.listing-info .checkout-nav {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.listing-info .checkout-nav li {
    width: calc(50% - 20px);
    margin: 0 20px 0 0;
    padding: 16px 10px;
    background-color: #00c49a;
    color: #404a5d;
}

.listing-info .checkout-nav li h2 {
    margin: 0;
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    color: #404a5d;
}

.listing-info .info {
    display: flex;
    width: 100%;
    padding: 20px;
    border: 2px solid #12C196;
}

.listing-info .info .image {
    margin: 6px;
    width: 100%;
    max-width: 300px;
}

.listing-info .info .image img {
    width: 100%;
    height: auto;
    display: block;
}

.listing-info .info .details {
    width: 100%;
}

.listing-info .info .details ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.listing-info .info .details ul li {
    margin: 5px 0;
    overflow: auto;
    font-size: 18px;
}

.listing-info .info .details ul li span {
    float: left;
    color: #7a8db1;
}

.listing-info .info .details ul li p {
    float: right;
    color: #333333;
}

.listing-info .info .details ul li p span {
    padding: 0 0 0 10px;
    color: #bb000b;
}

.listing-info .info .details ul li p .cross-text {
    text-decoration: line-through;
    color: #444;
}

.listing-info .info .details ul li a {
    margin: 0;
    color: #12C196;
}

.listing-info .info .details .total {
    padding: 0 0 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}

.listing-info .info .details .total span {
    background-color: #dde2eb;
    padding: 18px 10px;
    margin: 0 0 0 10px;
    width: 100%;
    text-align: right;
}

.checkout .form-control {
    background-color: #dde2eb;
    border: 0;
    border-radius: 0;
    height: 46px;
}

.checkout .form-group label {
    font-weight: 400;
    color: #333333;
}

.checkout .form-group label.error {
    color: #ffffff;
}

.checkout h3 {
    margin: 30px 0 30px 0;
    font-size: 20px;
    font-weight: 400;
}

.checkout .label-center {
    margin-bottom: 0;
}

.app-header {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgb(63, 76, 97);
}

.modal#tickets .modal-title {
    font-size: 18px;
    color: #1F252E;
    font-weight: 500;
}

.modal#tickets .ticket {
    border-bottom: 1px solid #424E62;
    width: 175px;
    margin: auto;
    padding-bottom: 10px;
}

.modal#tickets .minus,
.modal#tickets .plus,
.modal#tickets .value {
    display: inline-block;
}

.modal#tickets .minus {
    text-align: left;
}

.modal#tickets .plus {
    text-align: right;
}

.modal#tickets .minus,
.modal#tickets .plus {
    width: 17px;
    vertical-align: middle;
    padding-top: 5px;
}

.modal#tickets #minus,
.modal#tickets #plus {
    font-size: 27px;
    padding: 0;
}

.modal#tickets #ticket-value {
    min-height: auto;
    width: 133px;
    vertical-align: middle;
}

.modal#tickets .ticket-value {
    /*font-size: 50px !important;*/
    color: #12C196;
    padding: 0;
    text-align: center;
}

.modal#tickets .user-ticket {
    margin-top: 5px;
}

.modal#tickets #ticket {
    font-size: 21px;
}

.modal#tickets .user-ticket-value {
    font-size: 18px !important;
    margin-left: 10px;
    color: #aaa;
}

.modal#tickets .modal-footer {
    text-align: center;
    border: none;
    padding-bottom: 40px;
}

.modal#tickets #raffleId {
    color: #1F252E;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    background-color: #12C196;
    width: 220px;
    border-radius: 0;
}

#pre-requisite #raffleId {
    color: #1F252E;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    background-color: #66ccff;
    width: 220px;
    border-radius: 0;
}

.product-details h4 {
    font-size: 16px;
    color: #fff;
}

.product-details h4 span {
    margin-left: 10px;
}

@media (max-width: 767px) {
    .checkout {
        padding: 0;
    }
    .listing-info .checkout-nav li h2 {
        font-size: 16px;
    }
    .listing-info .info {
        flex-wrap: wrap;
    }
    .listing-info .info .image {
        margin-bottom: 20px;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .sq-payment-form {
        padding: 0 !important;
    }
    .sq-label {
        font-size: 12px !important;
    }
}

.tooltipster-sidetip.tooltipster-noir.tooltipster-noir-customized .tooltipster-box {
    background-color: #262c37 !important;
}

.tooltipster-sidetip.tooltipster-noir.tooltipster-noir-customized .tooltipster-content {
    padding: 15px !important;
    line-height: 140%;
}

.filters-toggle {
    padding-bottom: 10px;
    padding-top: 20px;
}

.filters-toggle a {
    display: inline-block;
    padding: 4px 10px;
    background-color: #e6e6e8;
    color: #666666;
    font-weight: 300;
    font-size: 15px;
}

.filters-toggle a i {
    padding: 0 0 0 20px;
    color: #09C297;
    font-size: 20px;
}

.my-listings-page,
.listing-view-page {
    max-width: 1570px;
    padding: 0 15px;
    margin: auto;
}

.m-send-sms {
    display: none;
    color: #000000;
}

.m-send-sms:hover {
    color: #00acee;
}

.d-send-sms {
    display: inline-block;
    color: #000000;
}

.register-btn {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.register-btn input {
    width: calc(70% - 20px);
    margin-top: 20px;
    background-color: #00c49a;
    font-size: 22px;
    color: #232832 !important;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.register-btn a {
    width: 30%;
    margin-top: 20px;
    background-color: #232832;
    font-size: 22px;
    color: #00c399;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

@media (max-width: 767px) {
    .my-listings-page,
    .listing-view-page {
        padding: 0 5%;
    }
    .m-send-sms {
        display: inline-block;
    }
    .d-send-sms {
        display: none;
    }
    .register-btn input,
    .register-btn a {
        width: 100%;
        margin: 0 0 10px 0;
    }
    .readysetgo p {
        font-size: 39px;
    }
}

.list-raffle {
    width: 100%;
    margin: 0 0 20px 0;
}

.list-raffle p {
    font-size: 15px;
    font-weight: 700;
}

.list-raffle span {
    font-weight: lighter;
    font-size: 16px;
}

.ticket-style {
    color: #00c49a;
    font-size: 30px;
    align-self: center;
}

.raffle-tickets {
    width: auto;
    margin: 0 0 20px 0;
    display: flex;
}

.raffle-tickets span {
    font-weight: lighter;
    font-size: 15px;
}

.raffle-tickets p {
    font-size: 16px;
    font-weight: 700;
}

.raffle-tickets-text {
    margin-left: 10px;
}

.raffle-tickets-text a {
    color: #00c49a;
}

.raffle-tickets-text span {
    color: #00c49a;
}

/*loader animation*/

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 18px;
}

.lds-ellipsis div {
    position: absolute;
    top: 5px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

/*end loader*/

.promo-page {
    text-align: center;
    margin: 0 5%;
}

.promo-page h2 {
    margin: 0 0 40px 0;
    font-weight: bold;
    font-size: 40px;
    color: #09C297;
    text-align: center;
}

.promo-page a {
    display: inline-block;
    padding: 12px 30px;
    color: #333;
    background-color: #09C297;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.promo-page .promo-block {
    margin: 50px 0;
}

.promo-page .promo-block i {
    font-size: 50px;
}

.promo-page .promo-block h3 {
    font-size: 22px;
    color: #09C297;
    text-align: center;
}

.promo-page .promo-block span {
    color: #ffffff;
    font-size: 18px;
}

.closed {
    -webkit-filter: blur(0.8px);
    /* Safari 6.0 - 9.0 */
    filter: sepia(90%) blur(0.8px);
}

input:focus {
    outline: none;
}

.share-buttons {
    align-items: center;
    display: flex;
}

.share-buttons h4 {
    cursor: pointer;
    color: #00c49a;
    font-size: 26px;
    margin-right: 20px;
}

.share-buttons i {
    margin-right: 10px;
    font-size: 40px;
    color: #00c49a;
    cursor: pointer;
}

.bonus-tickets h3 {
    font-size: 19px;
    font-family: 'Roboto', sans-serif;
    color: rgb(245, 245, 245);
}

.desc {
    line-height: 140%;
    height: 88px;
    overflow: hidden;
}

.show-more {
    color: rgb(102, 102, 102);
    cursor: pointer;
}

.game1 {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.game1 h2 {
    margin: 0 0 10px 0;
    font-size: 21px;
}

.game-image-container {
    width: auto;
    background: white;
    min-height: 180px;
    max-height: 380px;
    margin: 0 auto;
    display: block;
    border-radius: 20px 20px 0px 20px;
    -moz-border-radius: 20px 20px 0px 20px;
    -webkit-border-radius: 20px 20px 0px 20px;
}

.game-image {
    text-align: center;
    position: relative;
    width: 80%;
    height: 280px;
    /*max-height: 300px;*/
    /*min-height: 280px;*/
    margin: auto;
    margin-bottom: 0px;
}

.shadow {
    box-shadow: 7px 7px 0px 5px rgba(38, 43, 52, 0.7);
    -moz-box-shadow: 7px 7px 0px 5px rgba(38, 43, 52, 0.7);
    -webkit-box-shadow: 7px 7px 0px 5px rgba(38, 43, 52, 0.7);
}

.game1 .game-image img {
    /*width: 95%;*/
    /*min-height: 180px;*/
    /*max-height: 380px;*/
    /*margin: 0 auto;*/
    width: 100%;
    height: 100%;
    margin: 0 auto;
    object-fit: cover;
    display: block;
    border-radius: 20px 20px 0px 20px;
    -moz-border-radius: 20px 20px 0px 20px;
    -webkit-border-radius: 20px 20px 0px 20px;
}

.game-images {
    width: 55%;
    margin: auto;
}

.game1 .game-images img {
    width: 100%;
    object-fit: cover;
    margin-top: -20px;
    margin-bottom: 20px;
    display: block;
    border-radius: 20px 20px 0px 20px;
    -moz-border-radius: 20px 20px 0px 20px;
    -webkit-border-radius: 20px 20px 0px 20px;
}

.game1 .options {
    list-style: none;
    margin: 50px 0 40px 0;
    padding: 0;
}

.game1 .options li {
    margin: 40px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.game1 .options li a {
    display: block;
    width: 90%;
    padding: 35px 30px;
    text-align: center;
    color: #333;
    background-color: #09C297;
    font-size: 22px;
    font-weight: bold;
}

.game1 .load-msg {
    text-align: center;
    font-size: 2rem;
}

.theoptions {
    display: grid;
    grid-template-columns: 49% 49%;
    grid-template-rows: 50% 52%;
    grid-gap: 10px;
}

.theoptions .option {
    background: #12C196;
    padding: 20px 15px;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

.theoptions .option {
    cursor: pointer;
    color: #1F252E;
    font-weight: bold;
    text-decoration: none;
}

.discount-val {
    text-align: right;
}

.alerts-container {
    text-align: center;
}

.code {
    display: flex;
    width: 100%;
}

#discount-code {
    color: #0D1422;
    -webkit-text-fill-color: unset;
}

#discount-code::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgb(122, 141, 177);
}

#discount-code::-moz-placeholder {
    /* Firefox 19+ */
    color: rgb(122, 141, 177);
}

#coder {
    margin: 10px 0 10px 10px;
    width: 100%;
    cursor: pointer;
    white-space: nowrap;
}

#coder .lds-ellipsis div {
    background-color: #09C297;
}

.alerts {
    margin-top: 10px;
    font-size: 16px;
}

#coder .lds-ellipsis div {
    background-color: #09C297;
}

.timerbox {
    display: grid;
    grid-template-columns: auto;
    text-align: center;
    background: #fff;
    width: 300px;
    padding: 15px;
    margin: 20px auto;
    border-radius: 20px 20px 0px 20px;
    -moz-border-radius: 20px 20px 0px 20px;
    -webkit-border-radius: 20px 20px 0px 20px;
    box-shadow: 7px 7px 0px 5px rgba(38, 43, 52, 0.7);
    -moz-box-shadow: 7px 7px 0px 5px rgba(38, 43, 52, 0.7);
    -webkit-box-shadow: 7px 7px 0px 5px rgba(38, 43, 52, 0.7);
}

.timecontent {
    font-weight: bold;
    font-size: 25px;
}

.expired {
    margin: 50px 0;
    text-align: center;
}

.expired p {
    font-size: 25px;
    font-weight: bold;
}

p.tk-txt {
    font-size: 15px;
    font-weight: normal;
    margin-bottom: 40px;
}

a.play-nxt:hover {
    background: #0D1422;
    color: #09C297;
}

.game1 h2 {
    line-height: 127%;
}

a.play-nxt {
    cursor: pointer;
    background: #12C196;
    font-weight: bold;
    padding: 15px 10px;
    font-size: 20px;
    text-decoration: none;
}

.featured-cont {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 5px;
    margin-bottom: 20px;
}

.featitm {
    width: 80%;
    height: 80%;
    background: white;
    margin: 10px 10px 10px 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    -webkit-box-shadow: 6px 6px 0px 0px rgba(38, 46, 58, 1);
    -moz-box-shadow: 6px 6px 0px 0px rgba(38, 46, 58, 1);
    box-shadow: 6px 6px 0px 0px rgba(38, 46, 58, 1);
}

.oreo {
    width: 60%;
    height: 80%;
    background: white;
    margin: 10px 10px 10px 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    -webkit-box-shadow: 6px 6px 0px 0px rgba(38, 46, 58, 1);
    -moz-box-shadow: 6px 6px 0px 0px rgba(38, 46, 58, 1);
    box-shadow: 6px 6px 0px 0px rgba(38, 46, 58, 1);
}

.featitm img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: 216px;
}

.oreo img {
    width: 90%;
    height: 100%;
    object-fit: contain;
    max-height: 400px;
}

.load-fa {
    font-size: 7rem;
    color: #fff;
    text-align: center;
    width: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
}

.games-modal {
    color: #445063;
}

.modal-header i {
    font-size: 50px;
    top: 0;
}

.modal-body.cont p {
    color: #445063;
    font-size: 2.5rem;
    font-weight: bold;
}

button.btn-nxt-game {
    background: #12C196;
    border: none;
}

.games-modal .modal-header {
    padding: 0;
}

button.btn-nxt-game {
    color: #445063;
    background: #12C196;
    border: none;
    margin: 20px;
    padding: 10px 40px;
    font-size: 2rem;
    font-weight: bold;
}

.games-modal .modal-content {
    text-align: center;
}

.games-modal {
    padding-top: 0px;
}

#isClicked i {
    color: #12C196;
}

button.btn-nxt-game:active {
    background: #12C196;
}

button.btn-nxt-game:focus {
    background: #12C196;
}

button.btn-nxt-game:hover {
    background: #12C196;
}

button.btn-nxt-game:active:hover {
    background: #12C196;
}

button.btn-nxt-game:active:focus {
    background: #12C196;
}

.readysetgo {
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.bonus {
    /*width: 100%;*/
    /*overflow: hidden;*/
    position: relative;
    /*top: 50%;*/
    /*left: 50%;*/
}

.bonus p {
    position: absolute;
    transform: translate(13%, -174%);
    font-size: 5rem;
    color: red;
    font-style: italic;
    text-shadow: -1px 0 0px black, 0 1px black, 1px 0 black, 0 -1px black;
}

@media(max-width: 767px) {
    .bonus p {
        font-size: 5rem;
    }
    .desc {
        height: 155px;
    }
    .game-image {
        max-height: 350px;
        min-height: 350px;
        margin-bottom: 10px;
    }
    .game1 .game-image img {
        max-height: 350px;
    }
    .load-fa {
        font-size: 7rem;
        color: #fff;
        text-align: center;
        width: 100%;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-50%, -50%);
    }
}

p.letsgo {
    font-size: 80px;
    -webkit-text-stroke: 7px black;
    -webkit-text-fill-color: white;
    font-weight: 800;
    font-family: sans-serif;
}

i.fa.fa-times-circle {
    color: red;
}

i.fa.fa-times-circle.ans-wrong::before {
    color: red;
}

p.getready {
    color: #067c06;
    text-shadow: -2px 0 0px black, 0 2px black, 2px 0 black, 0 -2px black;
}

.dashboard h4 {
    font-weight: 700;
    color: #12C196;
}

.dashboard h3 {
    font-size: 20px;
    font-weight: 700;
}

.dashboard a {
    text-decoration: none;
}

.dashboard-cont {
    display: flex;
}

.day-game {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.dashboard-game {
    text-align: center;
    max-width: 230px;
    margin: auto;
    width: 70%;
}

.dsc-img {
    margin: 30px;
}

.ds-img {
    margin: 30px;
}

#discount-bonus .modal-footer {
    text-align: center;
    padding: 25px;
}

.dsc-bonus-txt p {
    color: #000;
    font-size: 20px;
}

.ds-bonus-txt p {
    color: #000;
    font-size: 20px;
}

div#dsc-footer {
    text-align: center;
    padding: 30px 15px 35px 15px;
}

div#ds-footer {
    text-align: center;
    padding: 30px 15px 35px 15px;
}

#isClicked .dsc-img {
    margin: 10px;
}

#isClicked .ds-img {
    margin: 10px;
}

#notClicked .dsc-img {
    margin: 10px;
}

#notClicked .ds-img {
    margin: 10px;
}

.bonus-close i.fa.fa-times-circle {
    color: #9a9a9a;
}

#isClicked .bonus-close i.fa.fa-times-circle {
    color: #9a9a9a;
}

.bonus-close {
    text-align: right;
    margin: 5px 20px 0 0;
}

.modal-body .dsc-bonus-txt {
    margin-bottom: 25px;
}

.modal-body .ds-bonus-txt {
    margin-bottom: 25px;
}

.surveyQ button {
    color: #445063;
    background: #12C196;
    border: none;
    margin: 5px;
    padding: 10px 40px;
    text-align: center;
}

.modal-body.surveyQ i {
    font-size: 5rem;
    color: #12C196;
}

.surveyBody span {
    font-size: 1.8rem;
    font-weight: bold;
}

button.btn.btn-success.btn-nxt-game.requireAnswering {
    background: #bdbdbd;
    color: #efefef;
}

p.requireAns {
    color: red;
    margin: 0;
}

#img2 {
    width: 35%;
}

@media(max-width: 500px) {
    #img2 {
        width: 60%;
    }
    .desc {
        height: 212px;
    }
    p.letsgo {
        font-size: 40px;
        -webkit-text-stroke: 4px black;
    }
    .game-image {
        max-height: 190px;
        min-height: 190px;
        width: 90%;
    }
    .load-fa {
        font-size: 7rem;
        color: #fff;
        text-align: center;
        width: 100%;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-50%, -50%);
    }
    .game1 .game-image img {
        max-height: 190px;
    }
    .day-game {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        width: 84%;
        max-height: 140px;
        min-height: 140px;
    }
    .drawing-pemium-btn h3 {
        padding: 10px;
        font-size: 16px;
    }
    .featitm img {
        width: 84%;
        max-height: 140px;
        /*min-height: 140px;*/
    }
    .oreo {
        width: 80%;
    }
    .oreo img {
        width: 84%;
        max-height: 400px;
        /*min-height: 140px;*/
    }
    .featured-cont {
        grid-gap: 15px;
    }
    a.play-nxt {
        cursor: pointer;
        background: rgb(18, 193, 150);
        font-weight: bold;
        padding: 15px 10px;
        font-size: 16px;
        text-decoration: none;
    }
    .day-game {
        width: 100% !important;
        max-height: 160px !important;
    }
    a.play-nxt.use-dsc-btn {
        font-size: 12px;
    }
    a.play-nxt.use-ds-btn {
        font-size: 12px;
    }
    .surveyQ button {
        color: #445063;
        background: #12C196;
        border: none;
        margin: 5px;
        padding: 5px 20px;
        text-align: center;
        display: inline-block;
        width: 80%;
    }
    .modal {
        overflow-y: auto;
    }
}

.live-page {}

.live-page h1 {
    font-size: 22px;
}

.live-page .live-page-content {
    margin: 10px 0;
    color: #fff;
}

.live-page .live-page-image {
    position: relative;
    display: block;
    margin: 20px 0;
    width: 100%;
}

.live-page .live-page-image .live-raffle-image {
    position: relative;
    width: 100%;
    padding: 0 0 20% 0;
    overflow: hidden;
}

.live-page .live-page-image .live-raffle-image img {
    position: absolute;
    left: 0;
    right: 0;
    transform: translate(0%, -20%);
    width: 100%;
}

.live-page img {
    width: 100%;
    height: auto;
}

.live-page .counter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .3);
    text-transform: uppercase;
    color: #fff;
    font-size: 32px;
    font-weight: bold;
    text-shadow: 2px 2px #555555;
}

.live-page .counter img {
    width: 250px;
    height: auto;
    max-width: 90%;
}

.live-page .live-page-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 20px 0;
}

.live-page .live-page-btn .live-share-buttons {
    /*width: calc(25% - 10px);*/
    /*!*min-height: 104px;*!*/
    /*padding: 20px 10px 20px 10px;*/
    /*margin: 0 10px;*/
    background-color: #313A47;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: center;
    color: #fff;
    cursor: pointer;
}

.live-page .live-page-btn .live-share-buttons span {
    display: block;
    padding: 10px 0 0 0;
    font-size: 16px;
}

.live-page .live-page-btn .live-share-buttons i {
    color: #00C49A;
    font-size: 30px;
    transform: rotate(45deg);
}

.live-page .raffle-description-row {
    background-color: #313A47;
}

.live-page .drawing-pemium-btn {
    margin: auto;
}

.live-page .drawing-block .drawing {
    padding: 10px 0 0 0;
    margin: 0;
    background-color: #1F252D;
    width: 100%;
    /*min-height: 150px;*/
}

.live-page .drawing-block .drawing h5 {
    font-size: 20px;
}

.live-page .drawing-block .drawing h4 {
    margin: 5px 0;
    font-size: 16px;
}

.live-page .drawing-block .drawing h3 {
    margin: 0 0 0 0;
}

.live-page .drawing-block .drawing-btn {
    height: auto;
}

.live-page .drawing-block .drawing-btn h3 {
    padding: 10px 0;
}

.live-page .live-page-products {
    width: 100%;
}

.live-page .live-page-products ul {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.live-page .live-page-products ul li {
    position: relative;
    width: calc(25% - 15px);
    margin: 0 20px 20px 0;
}

.live-page .live-page-products ul li h3 {
    color: #fff;
    font-size: 18px;
}

.live-page .live-page-products ul li a:hover,
.live-page .live-page-products ul li a h3:hover {
    color: #00c399;
    text-decoration: none;
}

.live-page .live-page-products ul li img {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px;
    -webkit-box-shadow: 10px 10px 0px 0px rgba(31, 37, 45, 1);
    -moz-box-shadow: 10px 10px 0px 0px rgba(31, 37, 45, 1);
    box-shadow: 10px 10px 0px 0px rgba(31, 37, 45, 1);
    background-color: #fff;
}

.live-page .live-page-products ul li:nth-child(4n) {
    margin-right: 0;
}

.live-page .live-page-products ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
}

.live-page .live-page-products ul li .tile-banner {
    position: absolute;
    top: -2px;
    right: -2px;
    display: flex;
    justify-content: center;
    align-items: initial;
    text-decoration: none;
    width: 80px;
    height: 80px;
    background-image: url("https://trusted-trader-bucket.s3.amazonaws.com/public/tile-banner.png");
    background-repeat: no-repeat;
    background-size: 80px 80px;
}

.live-page .live-page-products ul li .tile-banner span {
    display: block;
    padding: 10px 0 0 0;
    color: #fff;
    font-size: 14px;
}

.rotate {
    transform: rotate(45deg);
    /* Safari */
    -webkit-transform: rotate(45deg);
    /* Firefox */
    -moz-transform: rotate(45deg);
    /* IE */
    -ms-transform: rotate(45deg);
    /* Opera */
    -o-transform: rotate(45deg);
    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}

.live-page .live-page-products ul li img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

@media(max-width: 760px) {
    /*.live-page .live-page-btn .live-share-buttons, .live-page .drawing-block {*/
    /*    width: calc(50% - 10px);*/
    /*    margin: 0 20px 0 0;*/
    /*}*/
    .live-page .drawing-block {
        margin-right: 0;
    }
    .live-page .live-page-products ul li {
        width: calc(50% - 10px);
    }
    .live-page .live-page-products ul li:nth-child(2n) {
        margin-right: 0;
    }
    .live-page .counter {
        font-size: 22px;
    }
    .live-page h1 {
        margin-top: 0;
    }
    .live-page .live-page-btn .live-share-buttons {
        padding: 10px;
    }
    .live-page .live-page-btn .live-share-buttons span {
        font-size: 12px;
    }
    .live-page .drawing-block .drawing h5 {
        font-size: 14px;
    }
    .live-page .drawing-block .drawing h4 {
        font-size: 12px;
        height: auto;
    }
    .live-page .drawing-block .drawing-btn h3 {
        padding: 6px 0;
        font-size: 14px;
    }
}

.locked i {
    opacity: 1.0;
    color: #00c49a;
}

.locked a {
    align-self: center;
}

/*leaderboard*/

.leaderboard {
    margin: 0 15px;
}

.leaderboard p {
    line-height: 22px;
    text-align: justify;
}

.leaderboard h3 {
    text-align: left;
    font-size: 22px;
    font-weight: 900;
    color: #FFFFFF;
}

.leaderboard-container li {
    display: flex;
    margin: 10px;
}

.leaderboard-container ul {
    /*display: flex;*/
    padding: unset;
}

.leaderboard-container li {
    height: 25px;
    min-width: 125px;
    border-radius: 25px;
    padding: 8px;
    background-color: #00C49A;
    justify-content: center;
}

.leaderboard-container li>p {
    color: #1F252E;
    font-size: 18px;
    line-height: 18px;
    margin: 0;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.my-score {
    margin-bottom: 10px;
    height: 40px;
    background: #00C49A;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
    -webkit-box-shadow: 6px 6px 0px 0px rgba(38, 46, 58, 1);
    -moz-box-shadow: 6px 6px 0px 0px rgba(38, 46, 58, 1);
    box-shadow: 6px 6px 0px 0px rgba(38, 46, 58, 1);
}

#grid {
    align-content: center;
    text-align: right;
    display: grid;
    grid-template-columns: 9% 47% 41%;
}

.my-score>div>p {
    margin: 0;
}

.leaderboard-description {
    font-weight: 700;
    line-height: 140%;
    overflow: hidden
}

.leaderboard-description a {
    color: #00C49A;
    font-weight: 700;
}

.upgrade-to-prm {
    text-align: center;
    color: #fff;
    padding: 8px;
    margin-bottom: 10px;
    background: #00C49A;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
    -webkit-box-shadow: 6px 6px 0px 0px rgba(38, 46, 58, 1);
    -moz-box-shadow: 6px 6px 0px 0px rgba(38, 46, 58, 1);
    box-shadow: 6px 6px 0px 0px rgba(38, 46, 58, 1);
}

.upgrade-to-prm div#arrow-left {
    float: left;
    width: 10%;
    height: 20px;
    font-size: 20px;
}

.upgrade-to-prm div#txt-center {
    width: 79%;
    display: inline-block;
    margin: 0 auto;
    height: 20px;
}

.upgrade-to-prm div#arrow-right {
    float: right;
    width: 10%;
    height: 20px;
    font-size: 20px;
}

.score-container a:hover {
    text-decoration: none;
}

.show-more-description {
    color: #00c49a;
    cursor: pointer;
}

/*tabs*/

.fabs {
    bottom: 15px;
    position: fixed;
    margin: 1em;
    right: 0;
}

.fab {
    display: block;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    text-align: center;
    color: white;
    margin: 20px auto 0;
    box-shadow: 0px 5px 11px -2px rgba(0, 0, 0, 0.18), 0px 4px 12px -7px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    -webkit-transition: all .1s ease-out;
    transition: all .1s ease-out;
    position: relative;
}

.fab>i {
    font-size: 36px;
    line-height: 56px;
    transition: all .2s ease-in-out;
    transition-delay: 2s;
}

.fab.indigo {
    background: #3F51B5;
}

.fab.light-blue {
    background: #03A9F4;
}

.fab.teal {
    background: #12C196;
}

.fab:active,
.fab:focus,
.fab:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
}

.fab:not(:last-child) {
    width: 36px;
    height: 36px;
    margin: 20px auto 0;
    opacity: 0;
}

.fab:not(:last-child)>i {
    font-size: 18px;
    line-height: 40px;
    transition: all .3s ease-in-out;
}

.fabs:hover .fab:not(:last-child) {
    opacity: 1;
    width: 40px;
    height: 40px;
    margin: 15px auto 0;
}

.fab:nth-last-child(1) {
    -webkit-transition-delay: 25ms;
    transition-delay: 25ms;
}

.fab:not(:last-child):nth-last-child(2) {
    -webkit-transition-delay: 20ms;
    transition-delay: 20ms;
}

.fab:not(:last-child):nth-last-child(3) {
    -webkit-transition-delay: 40ms;
    transition-delay: 40ms;
}

.fab:not(:last-child):nth-last-child(4) {
    -webkit-transition-delay: 60ms;
    transition-delay: 60ms;
}

.fab:not(:last-child):nth-last-child(5) {
    -webkit-transition-delay: 80ms;
    transition-delay: 80ms;
}

[tooltip]:before {
    font-family: 'Roboto';
    font-weight: 600;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    background-color: #585858;
    color: #fff;
    content: attr(tooltip);
    font-size: 12px;
    visibility: hidden;
    opacity: 0;
    padding: 5px 7px;
    margin-right: 10px;
    position: absolute;
    right: 100%;
    bottom: 5%;
    white-space: nowrap;
}

[tooltip]:hover:before,
[tooltip]:hover:after {
    visibility: visible;
    opacity: 1;
}

/*end*/

.btn-exit-game {
    display: flex;
    float: right;
    border: none;
    padding: 4px 13px;
    font-size: 13px;
    font-weight: bold;
    background: #12C196;
    color: #fff;
}

.header-notification,
.general-header-notification {
    width: 100%;
}

.header-notification a,
.general-header-notification a {
    display: block;
    width: 100%;
    padding: 12px 10px;
    background-color: #00C49A;
    color: #1f1f1f;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.header-notification a:hover,
.general-header-notification a:hover {
    text-decoration: none;
}

.btn.fa.fa-times-circle {
    float: right;
    font-size: 4rem;
}

.modal-gameOver {
    border: none;
    padding: 0px 0px 60px;
}

.gameOver img {
    width: 150px;
    margin-bottom: 10px;
}

.doubletickets {
    color: rgb(68, 80, 99);
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    margin-top: 10px;
}

.btn-double-tickets {
    color: #445063;
    background: #12C196;
    border: none;
    margin: 0px 20px 20px;
    padding: 10px 40px;
    font-size: 3.3rem;
    font-weight: bold;
}

.btn.fa.fa-times-circle {
    float: right;
    font-size: 6rem;
}

.modal .modal-content .modal-header.gameOfDay {
    border: none;
    padding: 30px 30px 0px;
}

@media (min-device-width: 500px) and (max-device-width: 900px) {
    .modal-header {
        border: none;
        padding: 20px 30px 0px;
    }
    .game-images {
        width: 70%;
        margin: auto;
    }
    button.btn-nxt-game {
        margin: 0px 20px 20px;
        padding: 10px 40px;
        font-size: 2rem;
    }
}

@media(max-width: 500px) {
    .header-notification a,
    .general-header-notification a {
        font-size: 15px;
    }
    .btn-double-tickets {
        margin: 0px 20px 20px;
        padding: 10px 40px;
        font-size: 1.5rem;
        font-weight: bold;
    }
    .btn.fa.fa-times-circle {
        float: right;
        font-size: 4rem;
    }
    .modal-gameOver {
        border: none;
        padding: 0px 0px 40px;
    }
    .modal .modal-content .modal-header.gameOfDay {
        border: none;
        padding: 20px 20px 0px;
    }
    .oreo-modal {
        padding-top: 130px;
    }
    .game-images {
        width: 85%;
        margin: auto;
    }
    .games-modal {
        padding-top: 100px;
    }
    button.btn-nxt-game {
        margin: 0px 20px 20px;
        padding: 10px 40px;
        font-size: 2rem;
        font-weight: bold;
    }
    .modal-header {
        border: none;
        padding: 20px 30px 0px;
    }
}

@media(max-width: 330px) {
    .game-image {
        max-height: 150px;
        min-height: 150px;
    }
    .game-images {
        width: 85%;
        margin: auto;
        display: block;
    }
    .timerbox {
        margin: 10px auto 20px;
    }
    .games-modal {
        padding-top: 40px;
    }
    button.btn-nxt-game {
        color: #445063;
        background: #12C196;
        border: none;
        margin: 0px 20px 20px;
        padding: 10px 40px;
        font-size: 2rem;
        font-weight: bold;
    }
    .modal-header {
        border: none;
        padding: 20px 30px 0px;
    }
    .featured-cont {
        margin-bottom: 0px;
    }
}

a.fab.teal.share-button p {
    color: #fff;
    background: #12C196;
    font-size: 19px;
    font-weight: bold;
    border-radius: 5px;
}

.dsc-img.gameOfDay img:last-child {
    width: 60% !important;
}

.live-page .flash-page-products {
    width: 100%;
    margin: 30px 0 0 0;
}

.live-page .flash-page-products ul {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.live-page .flash-page-products ul li {
    width: calc(25% - 15px);
    margin: 0 20px 20px 0;
}

.live-page .flash-page-products ul li:nth-child(4n) {
    margin-right: 0;
}

.live-page .flash-page-products ul li .image {
    position: relative;
    background-color: #fff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px;
    -webkit-box-shadow: 10px 10px 0px 0px rgba(31, 37, 45, 1);
    -moz-box-shadow: 10px 10px 0px 0px rgba(31, 37, 45, 1);
    box-shadow: 10px 10px 0px 0px rgba(31, 37, 45, 1);
}

.live-page .flash-page-products ul li .image .tile-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
}

.live-page .flash-page-products ul li .tile-banner {
    position: absolute;
    top: -2px;
    right: -2px;
    display: flex;
    justify-content: center;
    align-items: initial;
    text-decoration: none;
    width: 80px;
    height: 80px;
    background-image: url("https://trusted-trader-bucket.s3.amazonaws.com/public/tile-banner.png");
    background-repeat: no-repeat;
    background-size: 80px 80px;
}

.live-page .flash-page-products ul li .tile-banner-red {
    position: absolute;
    top: -2px;
    right: -2px;
    display: flex;
    justify-content: center;
    align-items: initial;
    text-decoration: none;
    width: 80px;
    height: 80px;
    background-image: url("https://trusted-trader-bucket.s3.amazonaws.com/public/tile-banner-red.png");
    background-repeat: no-repeat;
    background-size: 80px 80px;
}

.live-page .flash-page-products ul li .tile-banner-red span {
    display: block;
    padding: 7px 4px 0 4px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    line-height: 100%;
    overflow-wrap: break-word;
}

.live-page .flash-page-products ul li .tile-banner span {
    display: block;
    padding: 7px 4px 0 4px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    line-height: 100%;
    overflow-wrap: break-word;
}

.live-page .flash-page-products ul li .tile-banner .mystery {
    font-size: 12px !important;
    padding: 8px 4px 0 4px !important;
}

.live-page .flash-page-products ul li img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

.mystery-bullets {
    margin: 15px 0;
    font-size: 18px;
}

.mystery-bullets li {
    padding: 4px 0;
}

.live-page .flash-page-products ul li .product-price {
    padding: 15px 0 10px 0;
    font-size: 16px;
    color: #fff;
    display: block;
}

.live-page .flash-page-products ul li .product-price span {
    padding: 0 0 0 5px;
    color: #00C49A;
}

.live-page .flash-page-products ul li .product-price .cross-price {
    text-decoration: line-through;
    color: #fff;
}

.blur-image {
    filter: blur(4px) grayscale(100%);
    -webkit-filter: blur(4px) grayscale(100%);
}

@media(max-width: 760px) {
    .live-page .flash-page-products ul li {
        width: calc(50% - 10px);
    }
    .live-page .flash-page-products ul li:nth-child(2n) {
        margin-right: 0;
    }
}

.red-menu {
    color: red !important;
}

.flash-modal {
    margin: 0 0 30px 0;
}

.flash-modal-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 0 30px 0;
}

.flash-modal-img img {
    max-width: 140px;
}

.flash-modal h3 {
    font-size: 18px;
    text-align: center;
    margin: 0 0 10px 0;
}

.flash-modal-btn {
    display: inline-flex;
    margin: 20px 0 0 0;
    padding: 12px 18px;
    color: #fff;
    font-weight: bold;
    background-color: #00C49A;
    border-radius: 3px;
}

.prediction {
    max-width: 600px;
    margin-top: 15px;
}

.predict-title {
    color: #F4F4F4;
    font-size: 22px;
    font-weight: 900;
    line-height: 28px;
}

p.pred-desc {
    color: #F4F4F4;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 20px;
    margin-top: -10px;
}

.pred-img {
    max-width: 500px;
    margin: 10px 70px;
}

.pred-img img {
    width: 100%;
    box-shadow: 9px 9px 0px 5px rgba(38, 43, 52, 0.7);
    -moz-box-shadow: 5px 5px 0px 5px rgba(38, 43, 52, 0.7);
    -webkit-box-shadow: 9px 9px 0px 5px rgba(38, 43, 52, 0.7);
    border-radius: 10px 10px 0px 10px;
    -moz-border-radius: 10px 10px 0px 10px;
    -webkit-border-radius: 10px 10px 0px 10px;
    height: 380px;
    object-fit: contain;
    background: #fff;
}

.pred-img a figure {
    width: 100%;
    box-shadow: 9px 9px 0px 5px rgba(38, 43, 52, 0.7);
    -moz-box-shadow: 5px 5px 0px 5px rgba(38, 43, 52, 0.7);
    -webkit-box-shadow: 9px 9px 0px 5px rgba(38, 43, 52, 0.7);
    border-radius: 10px 10px 0px 10px;
    -moz-border-radius: 10px 10px 0px 10px;
    -webkit-border-radius: 10px 10px 0px 10px;
}

.pred-btns {
    display: grid;
    grid-template-columns: auto auto;
    margin: 15px 75px;
    cursor: pointer;
}

.pred-btns>div {
    text-align: center;
    padding: 10px 0;
    font-size: 20px;
    font-weight: bold;
}

.pred-btn1 {
    background: #1DAA87;
}

.pred-btn1 p {
    font-weight: bold;
    margin: 0;
}

.pred-btn1 span {
    color: #fff;
    font-size: 17px;
}

.pred-btn2 p {
    font-weight: bold;
    color: #222;
    margin: 0;
}

.pred-btn2 span {
    color: #222;
    font-size: 17px;
}

.pred-btn2 {
    background: #12C196;
}

@media (max-width: 500px) {
    .pred-btns {
        margin: 15px 20px;
    }
    .pred-img {
        max-width: 500px;
        margin: 10px 40px;
    }
    .pred-btns>div {
        text-align: center;
        padding: 2px 0;
        font-size: 16px;
        font-weight: bold;
    }
}

.calendar-tickets-text {
    margin-left: 10px;
}

.calendar-tickets-text p {
    font-size: 15px;
}

.calendar-tickets-text p:first-child {
    font-size: 20px;
    font-weight: 700;
}

.raffle-ticket {
    width: auto;
    margin: 0 0 20px 0;
    display: flex;
}

.calendar-tickets-text span {
    color: #00c49a;
}

.number-pic img {
    width: 100%;
    max-height: 300px;
    min-height: 300px;
    background: white;
    margin: 0 auto;
    display: block;
    border-radius: 20px 20px 0px 20px;
    -moz-border-radius: 20px 20px 0px 20px;
    -webkit-border-radius: 20px 20px 0px 20px;
    object-fit: contain;
}

.number-pic video {
    /*width: 100%;*/
    max-height: 300px;
    min-height: 300px;
    background: white;
    margin: 0 auto;
    display: block;
    /*border-radius: 20px 20px 0px 20px;*/
    /*-moz-border-radius: 20px 20px 0px 20px;*/
    /*-webkit-border-radius: 20px 20px 0px 20px;*/
    object-fit: contain;
}

.num-pic p {
    margin: 0px;
}

.release-container {
    display: grid;
    grid-template-columns: 29% 29% 29%;
    grid-gap: 50px;
    padding: 10px;
}

.number-pic {
    position: relative;
}

.num-pic {
    position: absolute;
    top: 5px;
    right: 10px;
    background: #00c49a;
    padding: 3px 10px;
    border-radius: 5px;
}

.pred-img a figure img {
    height: 300px;
    object-fit: contain;
    background: #fff;
}

@media (max-width: 990px) {
    .number-pic img {
        width: 100%;
        max-height: 200px;
        min-height: 200px;
    }
    .number-pic video {
        /*width: 100%;*/
        max-height: 200px;
        min-height: 200px;
    }
}

@media (max-width: 768px) {
    .number-pic img {
        width: 100%;
        max-height: 180px;
        min-height: 180px;
    }
    .number-pic video {
        /*width: 100%;*/
        max-height: 180px;
        min-height: 180px;
    }
}

@media (max-width: 600px) {
    .pred-img a figure img {
        height: 170px;
        object-fit: contain;
        background: #fff;
    }
    .pred-img img {
        height: 300px;
        object-fit: contain;
        background: #fff;
    }
    .number-pic img {
        width: 100%;
        max-height: 180px;
        min-height: 180px;
    }
    .number-pic video {
        /*width: 100%;*/
        max-height: 180px;
        min-height: 180px;
    }
    .release-container {
        display: grid;
        grid-template-columns: 48% 48%;
        grid-gap: 10px;
        padding: 10px;
    }
}

.winner-leader {
    margin-bottom: 10px;
    height: 40px;
    background: #00C49A;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
    -webkit-box-shadow: 6px 6px 0px 0px rgba(38, 46, 58, 1);
    -moz-box-shadow: 6px 6px 0px 0px rgba(38, 46, 58, 1);
    box-shadow: 6px 6px 0px 0px rgba(38, 46, 58, 1);
}

.image-sorce {
    text-align: center;
}

.image-sorce a {
    text-decoration: none;
}

.release-tags li {
    display: inline-flex;
    border-radius: 5px;
    margin-right: 10px;
}

.release-tags li p {
    margin: 0;
    padding: 8px 16px;
}

.release-tags li a {
    color: #222;
    background: #038b6f;
    padding: 8px 16px;
    border-radius: 5px;
}

.release-tags li.active {
    background: #00ca9f;
}

.predic-detail {
    text-align: center;
    padding-top: 15px;
}

.predic-detail p {
    margin: 2px;
}

#ticketRange {
    width: 95%;
    margin: 5px auto;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 20px;
    border: 2px solid #12C196;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid #12C196;
    background: #fff;
    cursor: pointer;
}

.sliderticks-down {
    display: flex;
    justify-content: space-between;
    width: 45%;
    margin: 10px 50%;
}

.sliderticks-down p {
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 1px;
    background: #D3D3D3;
    height: 10px;
    line-height: 40px;
    margin: 0 0 20px 0;
}

.sliderticks-up {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 0 12px;
    padding: 0 15px;
    float: left;
}

.sliderticks-up p {
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 1px;
    background: #D3D3D3;
    height: 10px;
    line-height: 40px;
}

.bet-here {
    margin-left: 20px;
    font-weight: bold;
    color: red;
}

.win-here {
    color: #00c49a;
    margin-right: 20px;
    margin-top: -20px;
    font-weight: bold;
}

span#userT {
    color: #12c196;
    font-weight: bold;
    font-size: 20px;
}

.top-sellers h2 {
    font-size: 26px;
}

.top-sellers .header-image {
    margin: 20px 0 40px 0;
}

.top-sellers .header-image img {
    width: 100%;
    max-width: 100%;
}

.top-sellers ul {
    display: flex;
    margin: 15px 0;
    padding: 0;
    list-style: none;
}

.top-sellers ul:hover {
    background-color: rgba(0, 0, 0, 0.4);
    ;
}

.top-sellers ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33.3333%;
    padding: 0 10px;
    font-size: 24px;
    line-height: 120%;
    color: #fff;
}

.top-sellers ul li .sell-btn {
    display: inline-flex;
    color: #414c5e;
    background-color: #12c196;
    text-transform: uppercase;
    font-size: 22px;
    text-decoration: none;
    padding: 10px 25px;
    font-weight: bold;
}

@media (max-width: 600px) {
    .top-sellers ul {
        display: block;
        margin: 30px 0;
    }
    .top-sellers ul li {
        width: 100%;
        padding: 10px;
        text-align: center;
    }
}

.onlyleft {
    display: grid;
    grid-template-columns: auto;
    text-align: center;
    background: #fff;
    width: 300px;
    padding: 15px;
    margin: 20px auto;
    border-radius: 20px 20px 0px 20px;
    -moz-border-radius: 20px 20px 0px 20px;
    -webkit-border-radius: 20px 20px 0px 20px;
    box-shadow: 7px 7px 0px 5px rgba(38, 43, 52, 0.7);
    -moz-box-shadow: 7px 7px 0px 5px rgba(38, 43, 52, 0.7);
    -webkit-box-shadow: 7px 7px 0px 5px rgba(38, 43, 52, 0.7);
}

.leftcontent {
    font-weight: bold;
    font-size: 20px;
    -webkit-text-stroke: 0.4px #f9eded;
    -webkit-text-fill-color: red;
    position: absolute;
    top: 10px;
    right: 10px;
}

.buttons-slots {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00c49a;
    color: #404a5d;
    font-size: 22px;
    font-weight: bold;
    padding: 10px 15px;
    width: 80%;
    margin: 20px auto;
    pointer-events: none;
}

.checkbox-slot {
    text-align: center;
    padding: 20px;
}

.clickedslots {
    opacity: 1;
}

.img-container {
    width: 35%;
    margin: auto;
    position: relative;
    margin-bottom: 15px;
}

.img-container img {
    width: 100%;
    /*height: 250px;*/
    object-fit: contain;
    border-radius: 20px 20px 0px 20px;
    -moz-border-radius: 20px 20px 0px 20px;
    -webkit-border-radius: 20px 20px 0px 20px;
    box-shadow: 7px 7px 0px 5px rgba(38, 43, 52, 0.7);
    -moz-box-shadow: 7px 7px 0px 5px rgba(38, 43, 52, 0.7);
    -webkit-box-shadow: 7px 7px 0px 5px rgba(38, 43, 52, 0.7);
}

.onlyleft {
    position: sticky;
    width: 25%;
    margin: -20px auto 30px;
}

.detailcontent {
    margin: 30px auto;
    width: 90%;
}

.circle-board {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    grid-column-gap: 5px;
    grid-row-gap: 20px;
}

.item-circle {
    border-radius: 50%;
    background-color: #337ab7;
    display: inline-block;
    width: 50px;
    height: 50px;
    text-align: center;
    margin: auto;
    font-weight: bold;
    line-height: 50px;
    font-size: 20px;
    color: white;
}

.buttons-page {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 5px;
    opacity: 0.5;
}

.buttons-slots {}

.dropbtn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00c49a;
    color: rgb(64, 74, 93);
    padding: 10px 15px;
    font-size: 22px;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid transparent;
    width: 80%;
    margin: 20px auto;
    pointer-events: none;
}

.dropbtn:hover,
.dropbtn:focus {
    background-color: rgb(64, 74, 93);
    color: #00c49a;
}

.modal-congratz i.fa.fa-check-circle.ans-correct {
    color: #00c49a;
    font-size: 6rem;
    margin-top: -20px;
}

.row.buySLots {
    margin-right: -15px;
    margin-left: -15px;
    width: 70%;
    margin: auto;
}

.row.buySLots h2 {
    font-size: 32px;
    margin: 20px 0 20px 0;
}

.button-play {
    padding: 10px;
}

#buygame h3 {
    color: #445063;
}

#buygame p {
    color: #445063;
    width: 80%;
    margin: 20px auto;
    text-align: justify;
}

#afterBuyGame h3 {
    color: #445063;
}

#afterBuyGame p {
    color: #445063;
    width: 80%;
    margin: 20px auto;
    text-align: justify;
}

#nextRound h3 {
    color: #445063;
}

#nextRound p {
    color: #445063;
    width: 80%;
    margin: 20px auto;
    text-align: justify;
}

#mainPage h3 {
    color: #445063;
}

#mainPage p {
    color: #445063;
    width: 80%;
    margin: 20px auto;
    text-align: justify;
}

#ContgratulationModal h3 {
    color: #445063;
}

#ContgratulationModal p {
    color: #445063;
    width: 80%;
    margin: 20px auto;
    text-align: justify;
}

/*.img-container-buy{*/

/*    display: grid;*/

/*    grid-template-columns: auto auto;*/

/*    grid-column-gap: 5px;*/

/*}*/

.xbut {
    margin: auto;
    background: #333;
    padding: 5px;
    border-radius: 4px;
}

.buttons-buy i.fa.fa-arrow-circle-right {
    margin-left: 10px;
}

.totalBuy {
    padding: 20px 0;
    border-bottom: 1px solid white;
}

.billingShip {
    display: grid;
    grid-template-columns: auto auto;
    padding: 0;
}

.paymantM {
    display: grid;
    grid-template-columns: auto auto;
    padding: 20px 0;
    border-bottom: 1px solid white;
}

.playGameS {
    display: grid;
    grid-template-columns: auto auto;
    width: 70%;
    margin: auto;
}

.btn.fa.fa-times-circle.buyS {
    font-size: 3rem;
}

.billingShip p:last-child {
    text-align: end;
}

.totalBuy p:last-child {
    text-align: end;
}

.paymantM p:last-child {
    text-align: end;
}

.paycontent {
    padding: 20px;
}

/*.img-container-buy{*/

/*    display: grid;*/

/*    grid-template-columns: auto auto;*/

/*    grid-column-gap: 20px;*/

/*}*/

.img-container-buy img {
    object-fit: cover;
    margin: auto;
    width: 250px;
    /*border-radius: 20px 20px 0px 20px;*/
    /*-moz-border-radius: 20px 20px 0px 20px;*/
    /*-webkit-border-radius: 20px 20px 0px 20px;*/
    /*box-shadow: 7px 7px 0px 5px rgba(38, 43, 52, 0.7);*/
    /*-moz-box-shadow: 7px 7px 0px 5px rgba(38, 43, 52, 0.7);*/
    /*-webkit-box-shadow: 7px 7px 0px 5px rgba(38, 43, 52, 0.7);*/
}

.game-trophy-img img {
    object-fit: cover;
    margin: auto;
    width: 200px;
    border-radius: 20px 20px 0px 20px;
    -moz-border-radius: 20px 20px 0px 20px;
    -webkit-border-radius: 20px 20px 0px 20px;
    box-shadow: 7px 7px 0px 5px rgba(38, 43, 52, 0.7);
    -moz-box-shadow: 7px 7px 0px 5px rgba(38, 43, 52, 0.7);
    -webkit-box-shadow: 7px 7px 0px 5px rgba(38, 43, 52, 0.7);
}

i.fa.fa-caret-right {
    margin-left: 10px;
    color: #12C196;
}

.buttons-buy {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00c49a;
    color: #404a5d;
    font-size: 22px;
    font-weight: bold;
    padding: 10px 15px;
    width: 45%;
    margin: 20px auto;
}

.startSlotsGame {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00c49a;
    color: #404a5d;
    font-size: 22px;
    font-weight: bold;
    padding: 10px 15px;
    width: 50%;
    border-radius: 4px;
    margin: 20px auto;
    /* margin-bottom: 60px; */
}

.img-container-trophy {
    display: flex;
    /*align-items: center;*/
    justify-content: center;
    width: 100%;
    text-align: center;
    grid-column-gap: 20px;
}

.trophy-svg {
    text-align: center;
}

.trophy-board {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-column-gap: 5px;
    grid-row-gap: 35px;
    padding: 40px 20px;
}

.game-trophy-img.looked {
    pointer-events: none;
    opacity: 0.6;
}

svg.iconify:nth-child(1) {
    font-size: 5rem;
    margin: auto;
    color: #00c49a;
}

svg.iconify.red {
    color: red;
}

svg.iconify.yellow {
    color: gold;
}

svg.iconify.pink {
    color: deeppink;
}

.item-circle.transp {
    background-color: #19202A;
}

.item-circle.yellow {
    background-color: darkgoldenrod;
}

.slots-description-row {
    display: inline-block;
    padding: 10px 24px;
    background-color: #1a1f2a;
    border-radius: 10px 0px 10px 10px;
    -moz-border-radius: 10px 0px 10px 10px;
    -webkit-border-radius: 10px 0px 10px 10px;
}

.slots-tickets {
    width: auto;
    margin: 30px 0;
    display: flex;
}

.raffle-tickets-text h3 {
    margin-top: 5px;
}

.already-button {
    background-color: #09C297;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: 700;
    font-size: 25px;
    padding: 20px;
    text-align: center;
}

.buttons-auction {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00c49a;
    color: #404a5d;
    font-size: 22px;
    font-weight: bold;
    padding: 10px 15px;
    width: 25%;
    margin: 20px auto;
}

.img-containerAuction {
    width: 25%;
    margin: auto;
    position: relative;
}

.img-containerAuction img {
    width: 100%;
    /*height: 250px;*/
    object-fit: contain;
    border-radius: 20px 20px 0px 20px;
    -moz-border-radius: 20px 20px 0px 20px;
    -webkit-border-radius: 20px 20px 0px 20px;
    box-shadow: 7px 7px 0px 5px rgba(38, 43, 52, 0.7);
    -moz-box-shadow: 7px 7px 0px 5px rgba(38, 43, 52, 0.7);
    -webkit-box-shadow: 7px 7px 0px 5px rgba(38, 43, 52, 0.7);
}

@media (max-width: 1200px) {
    .img-containerAuction {
        width: 45%;
    }
    .buttons-auction {
        width: 30%;
    }
}

@media (max-width: 990px) {
    .row.buySLots {
        width: 80%;
    }
    .img-container-buy img {
        width: 150px;
    }
    .playGameS {
        width: 90%;
    }
    .img-container {
        width: 50%;
    }
    .img-containerAuction {
        width: 50%;
    }
    .img-containerAuction {
        width: 50%;
    }
    .onlyleft {
        width: 35%;
    }
    .buttons-auction {
        width: 45%;
    }
}

@media (max-width: 768px) {
    .row.buySLots {
        width: 90%;
    }
    .img-container {
        width: 60%;
    }
    .img-containerAuction {
        width: 60%;
    }
    /*.img-container img {*/
    /*   height: 300px;*/
    /*}*/
    .onlyleft {
        width: 35%;
    }
    .img-container-buy img {
        width: 150px;
    }
    .playGameS {
        width: 100%;
    }
    .buttons-slots {
        width: 100%;
    }
    .startSlotsGame {
        width: 50%;
    }
    .trophy-board {
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-column-gap: 5px;
        grid-row-gap: 35px;
        padding: 40px 20px;
    }
    .trophy-board span {
        font-size: 14px;
    }
}

.dsc-img.confirm img {
    max-height: 200px;
}

.drop-previous-btn {
    background-color: #00ca9f;
    padding: 15px;
    text-align: center;
    border-radius: 5px;
    width: fit-content;
    margin: 20px auto;
}

.drop-previous-btn p {
    font-size: 24px;
    color: #1a1f2a;
    margin-bottom: 0;
    font-weight: bold;
}

@media (max-width: 500px) {
    .drop-previous-btn p {
        font-size: 14px;
    }
    .buttons-auction {
        width: 90%;
    }
    .dsc-img.confirm {
        margin: 0;
    }
    .dsc-img.confirm img {
        max-height: 150px;
        width: 100%;
        margin: 0;
    }
    .row.buySLots {
        width: 100%;
    }
    .title-slots {
        margin: 0px 0px 20px 40px;
    }
    .title-slots h3 {
        margin-top: 0;
    }
    .slots-description-row {
        padding: 0 10px;
    }
    .slots-tickets {
        width: auto;
        margin: 20px 0;
    }
    .img-container {
        width: 100%;
    }
    .img-containerAuction {
        width: 100%;
    }
    .circle-board {
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-column-gap: 5px;
        grid-row-gap: 15px;
    }
    .img-container img {
        width: 100%;
        /*height: 250px;*/
        object-fit: contain;
        border-radius: 0;
        box-shadow: none;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
    }
    .img-containerAuction img {
        width: 100%;
        height: 200px;
        object-fit: contain;
        border-radius: 0;
        box-shadow: none;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        background-color: white;
    }
    .onlyleft {
        position: sticky;
        width: 70%;
        margin: -20px auto 30px;
    }
    .buttons-buy {
        padding: 10px 15px;
        font-size: 15px;
        width: 75%;
    }
    .buttons-page {
        padding: 0px 15px;
    }
    .dropbtn {
        padding: 0;
        font-size: 15px;
        width: 100%;
    }
    .buttons-slots {
        padding: 10px 15px;
        font-size: 15px;
    }
    .leftcontent {
        top: 10px;
        right: 5px;
        font-size: 15px;
    }
    .detailcontent {
        margin: 20px auto;
        width: 90%;
        text-align: justify
    }
    .img-container-buy {
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: 20px;
    }
    .img-container-buy img {
        width: 150px;
    }
    .game-trophy-img img {
        width: 120px;
    }
    .totalBuy {
        grid-template-columns: auto auto;
        padding: 20px 0;
        border-bottom: 1px solid white;
    }
    .billingShip {
        display: grid;
        grid-template-columns: auto auto;
        padding: 0;
    }
    .paymantM {
        display: grid;
        grid-template-columns: auto auto;
        padding: 20px 0;
        border-bottom: 1px solid white;
    }
    .img-container-trophy {
        grid-template-columns: auto auto;
        width: 100%;
        grid-column-gap: 20px;
    }
    .trophy-svg svg.iconify {
        font-size: 3rem;
        margin: auto;
    }
    .playGameS {
        display: grid;
        grid-template-columns: auto auto;
        width: 100%;
    }
    .btn.fa.fa-times-circle.buyS {
        font-size: 3rem;
    }
    .billingShip p:last-child {
        text-align: end;
        color: #12C196;
    }
    .totalBuy p:last-child {
        text-align: end;
    }
    .paymantM p:last-child {
        text-align: end;
        color: #12C196;
    }
    .paycontent {
        padding: 20px;
    }
    .xbut {
        padding: 0;
    }
    .startSlotsGame {
        width: 80%;
    }
    .trophy-board {
        display: grid;
        grid-template-columns: auto;
        grid-column-gap: 5px;
        grid-row-gap: 5px;
        margin: 20px auto;
        padding: 5px;
    }
    .raffle-tickets-text h3 {
        margin-top: 15px;
    }
    .img-container-trophy {
        display: block;
    }
}

/*.slot-payment-form label{*/

/*    color: #ffffff;*/

/*}*/

/*.slot-user-info label{*/

/*    color: #ffffff !important;*/

/*}*/

.slot-total-amount h3 {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.sq-button {
    background-color: #00c399 !important;
}

.slot-card-error {
    list-style: none;
    margin: 0;
    padding: 0 0 20px 0;
}

.justify-content-center {
    justify-content: center;
}

.aweber-mystery-btn input {
    width: 70%;
    background-color: #00c49a;
    font-size: 22px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: none;
    margin: 10px auto;
}

input.mystery-aw-input {
    width: 70%;
    padding: 0 15px;
    background: #E3E3E3;
    border-radius: 0;
    border: none;
    font-size: 16px;
    line-height: 40px;
    height: 60px;
    box-shadow: 0 0 black;
    -webkit-text-fill-color: #333333;
    opacity: 1;
}

.slotsPage h3 {
    color: #000000;
}

input.al-default-input {
    width: 70%;
    padding: 0 15px;
    background: #E3E3E3;
    border-radius: 0;
    border: none;
    font-size: 16px;
    line-height: 40px;
    height: 60px;
    box-shadow: 0 0 black;
    -webkit-text-fill-color: #333333;
    opacity: 1;
}

.al-default-btn {
    width: 70%;
    background-color: #00c49a;
    font-size: 22px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: none;
    margin: 10px auto;
}

.row-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 20px 0 40px 0;
    list-style: none;
}

.row-buttons li {
    margin: 0 10px;
    width: 100%;
    max-width: 50px;
}

.row-buttons li a {
    padding: 8px 16px;
    display: block;
    color: #fff;
    background-color: #00c399;
    font-size: 18px;
}

.tile-price {
    margin: 15px 0px;
    color: #00c49a;
    font-weight: 900;
    font-size: 1.8rem;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.tile-price .cross-text {
    color: #e60000;
    text-decoration: line-through;
    margin-right: 10px;
    font-weight: 100;
}

.drop-banner {
    display: block;
    padding: 12px 12px 0 4px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    line-height: 100%;
    overflow-wrap: break-word;
    position: absolute;
    top: -2px;
    right: -2px;
    display: flex;
    justify-content: center;
    align-items: initial;
    text-decoration: none;
    width: 80px;
    height: 80px;
    background-image: url(https://trusted-trader-bucket.s3.amazonaws.com/public/tile-banner.png);
    background-repeat: no-repeat;
    background-size: 80px 80px;
}

.num-pic.drop-pic {
    left: 10px;
    right: initial;
}

.drop-sold-out {
    background-image: url(https://trusted-trader-bucket.s3.amazonaws.com/public/tile-banner-red.png);
    padding: 7px;
    font-size: 15px;
}

.annual {
    background-image: url(https://trusted-trader-bucket.s3.amazonaws.com/public/gold-banner.png);
    padding: 10px;
    font-size: 13px;
    color: #000;
    font-weight: bold;
}

.sale-table {
    margin: 3rem 0 6rem 0;
    border-collapse: collapse;
    padding: 0;
    width: 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-collapse: inherit;
}

.sale-table tr {
    border-bottom: 1px solid #D2D2D2;
}

.sale-table tr th {
    padding: 15px 15px;
    background-color: #1A1F2A;
    color: #ffffff;
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    border-right: 1px solid #2e3649;
}

.sale-table tr th:first-child {
    -webkit-border-top-left-radius: 12px;
    -moz-border-radius-topleft: 12px;
}

.sale-table tr th:last-child {
    border-right: 0;
    -webkit-border-top-right-radius: 12px;
    -moz-border-radius-topright: 12px;
}

.sale-table tr th.fixed {
    width: 200px;
}

.sale-table tr td {
    position: relative;
    padding: 20px 20px;
    vertical-align: top;
    font-size: 18px;
    border-bottom: 1px solid #D2D2D2;
    border-right: 1px solid #f0f0f0;
    background-color: #f8f8f8;
}

.sale-table tr td:last-child {
    border-right: 0;
}

.sale-table tr td.checkmark {
    text-align: center;
    font-size: 26px;
    color: #00c399;
    vertical-align: middle;
}

.sale-table tr td .ticket-style {
    font-size: 24px;
    cursor: pointer;
    color: #2c3542;
}

.sale-table tr td.centered {
    text-align: center;
    color: #000000;
}

.sticky-header {
    position: sticky;
    top: 70px;
    z-index: 10;
}

.sale-table tr td .sale-btn {
    padding: 8px 12px;
    display: inline-flex;
    color: #ffffff;
    border-radius: 3px;
}

.sale-table tr td .sale-btn:hover {
    text-decoration: none;
}

.sale-table tr td .green-btn {
    background-color: #0f9b78;
}

.sale-table tr td .grey-btn {
    background-color: #666666;
}

@media (max-width: 780px) {
    .sale-table tr th {
        padding: 10px 5px;
        font-size: 14px;
    }
    .sale-table tr th.fixed {
        width: 120px;
    }
    .sale-table tr td {
        padding: 15px 10px;
        font-size: 14px;
    }
    .sale-table tr td .ticket-style {
        font-size: 18px;
    }
    th:nth-of-type(1)::before,
    th:nth-of-type(2)::before,
    th:nth-of-type(3)::before {
        content: "";
    }
    .sale-table tr th:first-child {
        -webkit-border-top-left-radius: 0;
        -moz-border-radius-topleft: 0;
    }
    .sale-table tr th:last-child {
        -webkit-border-top-right-radius: 0;
        -moz-border-radius-topright: 0;
    }
}

.live-prods img {
    min-height: 225px;
    max-height: 225px !important;
    object-fit: contain;
    width: 100% !important;
}

.drawing-block.prem-live>.drawing {
    background: none;
    background: #09c297;
    padding: 30px;
}

p.releToday {
    font-size: 12px;
}

.tile-price span.prod-bubble {
    background: rgba(0, 0, 0, 0.4);
    padding: 0px 10px;
    border-radius: 10px;
    color: #fff;
    font-weight: 100;
    font-size: 1.6rem;
    margin-left: 5px;
    white-space: nowrap;
}

@media (max-width: 600px) {
    .divBbl {
        width: 100%;
        /*margin-left: -5px;*/
    }
}

.twoButtons li {
    list-style: none;
    padding: 20px;
    background: #222;
    margin: 5px;
    color: #fff;
    cursor: pointer;
}

.twoButtons {
    display: inline-flex;
}

li.twobtns-green {
    background: #00c49a;
}

li.twobtns-red {
    background: #b80014;
}

.twoButtons a {
    list-style: none;
    padding: 20px;
    background: #222;
    margin: 5px;
    color: #fff;
    cursor: pointer;
}

.twoButtons {
    display: inline-flex;
}

a.twobtns-green-before {
    background: #00c49a;
}

a.twobtns-red-before {
    background: #b80014;
}

.featuredSection ul li .info h4 {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
}

.featuredSection h2 {
    font-size: 25px;
    color: #12c196;
}

a.product-price .tile-price .cross-text {
    color: red !important;
}

a.product-price .tile-price {
    font-size: 18px;
    margin: 10px 0;
    flex-wrap: nowrap;
}

a.product-price .tile-price span {
    margin: 0;
}

a.product-price .tile-price .divBbl {
    margin-left: 5px;
}

a.product-price .tile-price .divBbl .prod-bubble {
    padding: 2px 5px;
}

#connectButton {
    margin: 0 auto;
    border: thin solid black;
    padding: 10px 20px;
    font-size: 10px;
    font-weight: bold;
    display: none;
    border-radius: 25px;
}

.product-info h5.newH5 img {
    margin-right: 3px;
}

.listing-list ul.mobileWeb3 {
    grid-template-columns: auto auto auto;
    width: 90%;
    margin: 0 auto;
    gap: 10px 0;
}

.product-info h5.newH5 {
    cursor: pointer;
    width: fit-content;
    display: flex;
    align-items: center;
    margin: 0 2px 5px;
    font-weight: 700;
    font-size: 15px;
    background-color: #66ccff;
    padding: 5px 9px;
    border-radius: 20px;
}

span.we3span {
    width: fit-content;
    align-items: center;
    margin: 0 2px 5px;
    font-weight: 700;
    font-size: 15px;
    background-color: #66ccff;
    padding: 5px 9px;
    border-radius: 20px;
}

span.we3span img {
    width: 20px;
    height: 20px;
}

img.logoPrice {
    max-height: 20px;
    min-height: 20px;
    background: none;
    cursor: pointer;
}

.listing-list ul li.newListyle {
    width: 31%;
    margin: 0 auto;
    /*margin: 5px;*/
}

.product-info h5.newH5:first-child {
    /*font-size: 17px;*/
    font-size: 10px;
    text-align: center;
}

.divIMG {
    text-align: center;
}

.containIMg {
    display: none;
    text-align: center;
}

.containVid {
    display: none;
    text-align: center;
}

.closeIframe {
    font-size: 40px;
    float: right;
}

.closeIframe i.fa.fa-times-circle {
    color: #66ccff;
}

.containVid video {
    height: calc(100vh - 100px);
    width: calc(100vw - 0px);
    box-sizing: border-box;
}

.divIMG img {
    width: 60%;
}

.qrAda {
    width: 200px;
    margin: 10px 0;
    display: block;
}

.newWallet {
    width: 130px;
    margin: 0 auto;
}

.newWallet img {
    width: 100%;
    border-radius: 50px;
}

.nonOwner {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
}

span.questionMark {
    position: absolute;
    width: 30px;
}

@media (max-width: 1400px) {
    .rounded-rectangle-3 {
        padding: 0;
    }
    .featuredSection.web3Fautured {
        padding: 0;
        margin: 0;
    }
    .listing-list ul.mobileWeb3 {
        width: 100%;
    }
    .listing-list ul li.newListyle {
        width: 32%;
    }
    .product-info h5.newH5 {
        margin: 0 0 5px;
        font-size: 12px;
    }
    .product-info h5.newH5 img {
        width: 18px;
        margin-right: 2px;
    }
    .product-info h5.newH5:first-child {
        font-size: 16px;
    }
}

/*@media (max-width: 1030px) {*/

/*    .featuredSection.web3Fautured{*/

/*        padding: 0;*/

/*        margin: 0;*/

/*    }*/

/*    .listing-list ul.mobileWeb3 {*/

/*        width: 90%;*/

/*    }*/

/*    !*.listing-list ul li.newListyle{*!*/

/*    !*    width: 230px;*!*/

/*    !*    margin: 5px;*!*/

/*    !*}*!*/

/*}*/

@media (max-width: 770px) {
    .listing-list ul.mobileWeb3 {
        width: 100%;
    }
    #connectButton {
        margin: 0;
    }
    .featuredSection.web3Fautured {
        padding: 0;
        margin: 0;
    }
    .listing-list ul.mobileWeb3 {
        grid-template-columns: auto auto auto;
        grid-gap: 0 10px;
        padding: 0;
    }
    /*.listing-list ul li.newListyle{*/
    /*    width: 230px;*/
    /*    margin: 5px;*/
    /*}*/
    .newWEb3 {
        display: grid;
        grid-template-columns: auto auto;
    }
    .product-info h5.newH5 {
        margin: 0 0 5px;
        font-size: 18px;
    }
    .product-info h5.newH5:first-child {
        font-size: 18px;
    }
    .product-info h5.newH5 img {
        /*width: 25px;*/
        margin-right: 2px;
    }
}

@media (max-width: 500px) {
    .qrAda {
        width: 300px;
        margin: 10px auto;
    }
    .containIMg img {
        width: 100%;
        /*margin-top: 180px*/
    }
    .containIMg {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .containVid {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .containVid video {
        height: auto;
    }
    #connectButton {
        margin: 0;
    }
    .rounded-rectangle-3 {
        padding: 0;
    }
    .featuredSection.web3Fautured {
        padding: 0;
        margin: 0;
    }
    .listing-list ul.mobileWeb3 {
        grid-template-columns: auto auto;
        grid-gap: 0 20px;
        padding: 0;
    }
    .listing-list ul li.newListyle {
        width: 175px;
        margin: 0px;
    }
    .newWEb3 {
        display: grid;
        grid-template-columns: auto auto;
        gap: 5px 0;
    }
    h5.newH5.lastyOne {
        grid-column: 1 / span 3;
        grid-row: 2;
    }
    .product-info h5.newH5 {
        margin: 0 auto;
        font-size: 12px;
    }
    .product-info h5.newH5:first-child {
        font-size: 15px;
    }
    .product-info h5.newH5:first-child br {
        display: none;
    }
    .product-info h5.newH5 img {
        /*width: 25px;*/
        margin-right: 2px;
    }
    .number-pic img {
        width: 100%;
        max-height: 140px;
        min-height: 140px;
    }
    .number-pic video {
        /* width: 100%; */
        max-height: 140px;
        min-height: 140px;
    }
    img.logoPrice {
        max-height: 15px;
        min-height: 15px;
    }
    .listing-view .listing-left {
        margin: 0;
    }
    .listing-view>.listing-left>.images>.current-image {
        padding: 0;
    }
}

@media (max-width: 380px) {
    /*.container.weeb3{*/
    /*    padding-right: 0;*/
    /*    padding-left: 0;*/
    /*}*/
    .rounded-rectangle-3 {
        padding: 0;
    }
    .listing-list ul.mobileWeb3 {
        grid-gap: 0 3px;
        padding: 0;
    }
    .listing-list ul li.newListyle {
        width: 165px;
        margin: 3px;
    }
    .product-info h5.newH5 img {
        width: 15px;
        height: 15px;
        margin-right: 0;
    }
    .product-info h5.newH5:first-child {
        font-size: 13px;
    }
}

@media (max-width: 321px) {
    .number-pic video {
        /* width: 100%; */
        max-height: 140px;
        min-height: 140px;
    }
    #connectButton {
        margin: 0;
        font-size: 15px;
    }
    .listing-list ul li.newListyle {
        width: 147px;
        margin: 0 3px;
    }
    .listing-list ul.mobileWeb3 {
        grid-gap: 0;
        padding: 0;
    }
    /*.product-info h5.newH5{*/
    /*   */
    /*    font-size: 10px;*/
    /*}*/
    .product-info h5.newH5 img {
        width: 12px;
        /*margin-right: 2px;*/
    }
    .tile-price {
        font-size: 11px;
    }
    .tile-price span.prod-bubble {
        font-size: 1rem;
    }
}

.twocol-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.theItem {
    width: 48%;
    max-width: 300px;
}

.theItem a img {
    width: 100%;
    object-fit: contain;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    -webkit-box-shadow: 6px 6px 0px 0px rgba(38, 46, 58, 1);
    -moz-box-shadow: 6px 6px 0px 0px rgba(38, 46, 58, 1);
    box-shadow: 6px 6px 0px 0px rgba(38, 46, 58, 1);
    margin-bottom: 10px;
}

#liveVisitorsPopup {
    display: none;
    position: fixed;
    background: #fff;
    bottom: 0;
    left: 0;
    margin: 20px 10px;
    border-radius: 20px;
    padding: 10px;
    max-width: 300px;
    box-shadow: 0px 0px 20px 0px #0000003b;
}

div#liveVisitorsPopup p {
    text-align: right;
    color: #000;
    margin: 0;
}

span.vis-closePop {
    padding: 2px 6px;
    color: #222;
    background: #12c196;
    border-radius: 50%;
}

.vis-headerPop {
    padding: 10px;
}

.vis-headerPop img {
    margin-right: 5px;
}

span.vis-count {
    font-weight: bold;
}

span.vis-popText {
    font-size: 15px;
}
